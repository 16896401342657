import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';
import firebase from 'firebase/compat/app';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FontsService } from '../fonts.service';
import { DataService } from '../shared/services/data.service';
@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
    test : Date = new Date();
    focus;
    focus1;
    focus2;
  
    nameTaken: any;

    isName: any;
    yours: boolean;
    isTaken: any;
    changed: boolean;
    profileForm = new FormGroup({
        password: new FormControl(''),

        email:new FormControl(''),
      
        displayName: new FormControl(''),
      });
    constructor(public auth:AuthService, public router: Router,public afs: AngularFirestore,private calendar: NgbCalendar, public dataService: DataService,public fontService:FontsService, public functions: AngularFireFunctions, 
        private afStorage: AngularFireStorage,
        public afAuth: AngularFireAuth,) { 
        this.profileForm.valueChanges.subscribe(value => {
            const inputName = value.displayName.replace(/[^a-z0-9]/gi, '');
            
               const newName = '@'+inputName.toLowerCase()
              
             this.nameTaken;
  
             this.afs.collection('users/', ref=>ref.where("displayName", "==",  newName)).valueChanges().subscribe(name =>{
               this.isName = name;
             this.yours = false
               this.isTaken = this.isName[0]?.displayName
          
             
               if(this.isTaken == newName ){
                this.nameTaken = true;
            
              
                this.changed = false
               }
               if(this.isTaken != newName){
                this.nameTaken = false;
                this.changed = true;
               }
             })
             const db = firebase.firestore();
  
       
             
            });
    }

    ngOnInit() {}
}
