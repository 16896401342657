import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-foundation',
  templateUrl: './business-foundation.component.html',
  styleUrls: ['./business-foundation.component.css']
})
export class BusinessFoundationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
