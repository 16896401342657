import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import firebase from 'firebase/compat/app';
import { map } from 'rxjs/operators';
// import { firestore } from 'firebase/app';
import { AuthService } from './auth.service';
import Swal from 'sweetalert2'
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FontsService } from 'src/app/services/fonts.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  mySubscribesRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  mysubscribes: any;
  lowercase: any;
  upperCase: any;
  isLoading: boolean;

  confirmation: any;
  resp: any;
  str: any;
  value: number;
  nameEmail: number;
  nameEmailPhone: number;
  novalue: boolean;
  data: any;
  popularRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  popular: any;
  noDupe: boolean;
  dupe: any;
  fontRef: any;




  constructor(public afs: AngularFirestore, public auth:AuthService,public afAuth: AngularFireAuth,private functions: AngularFireFunctions,private afStorage: AngularFireStorage,public fonts: FontsService ) {
    this.data = this.afs.doc('inputs/landing').valueChanges();
    this.mostPopular()
    // this.afAuth.authState.subscribe(async user => {
    //   if (user) {
    // // const fun = this.functions.httpsCallable('getFonts');
    // // this.fontRef = await fun({ }).toPromise();
    // // console.log(this.fontRef)
    //   }})
   }
  subscribe(id, path,low, med, high){
  
    this.upperCase = path.toUpperCase()

    const increment = firebase.firestore.FieldValue.increment(1)
    const incrementLow = firebase.firestore.FieldValue.increment(+low)
    const incrementMed = firebase.firestore.FieldValue.increment(+med)
    const incrementHigh = firebase.firestore.FieldValue.increment(+high)
    this.afs.doc('keywords/' + this.upperCase).set({
      subscribers: firebase.firestore.FieldValue.arrayUnion(id),
      subscriberCount: increment,
      keyword:this.upperCase,

    },{merge:true});
    this.afs.doc('users/'+id).update({
      keywords: increment,
      keywordList: firebase.firestore.FieldValue.arrayUnion(this.upperCase),
      low: incrementLow,
      med: incrementMed,
      high: incrementHigh
    }).then();{
      Swal.fire(
        'Success!',
        'You are subscribed!',
        'success'
      )
    }
  }
  unsubscribe(id, path,low, med, high){

    this.upperCase = path.toUpperCase()
    const lowside = +low
    const highside = +high
    const median = +med
    const incrementLow = firebase.firestore.FieldValue.increment(-lowside)
    const incrementMed = firebase.firestore.FieldValue.increment(-median)
    const incrementHigh = firebase.firestore.FieldValue.increment(-highside)
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, unsubscribe!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Done!',
          'You have been unsubscribed from that keyword',
          'success'
        )
        const increment = firebase.firestore.FieldValue.increment(-1)
        this.afs.doc('keywords/' + this.upperCase).update({
          subscribers: firebase.firestore.FieldValue.arrayRemove(id),
          subscriberCount: increment
        });
        this.afs.doc('users/'+id).update({
          keywords: increment,
          keywordList:firebase.firestore.FieldValue.arrayRemove(this.upperCase),
          low: incrementLow,
      med: incrementMed,
      high: incrementHigh
        })
      }
    })
  
  }
  mySubscriptions(){
    
    this.afAuth.authState.subscribe(user => {
      if (user) {
       
     this.mySubscribesRef = this.afs.collection('keywords', ref => ref.where('subscribers', 'array-contains', user.uid).orderBy('subscriberCount','desc'));
     this.mysubscribes = this.mySubscribesRef.snapshotChanges().pipe(
       map(actions => actions.map(a => {
         const data = a.payload.doc.data();
         const id = a.payload.doc.id;
        
         return { id, ...data };
       }))
     );
  
  
      }})
  }
  mostPopular(){
  
     this.popularRef = this.afs.collection('keywords', ref => ref.limit(25).orderBy('subscriberCount','desc'));
     this.popular = this.popularRef.snapshotChanges().pipe(
       map(actions => actions.map(a => {
         const data = a.payload.doc.data();
         const id = a.payload.doc.id;
     
         return { id, ...data };
       }))
     );


  }
  async check(value){
    this.upperCase = value.toUpperCase()
    this.dupeCheck(this.upperCase)
    this.isLoading = true

    const fun = this.functions.httpsCallable('keywords');
    this.confirmation = await fun({
    
      keyword: value
    }).toPromise().then(); 
          {
            this.resp = this.confirmation
            this.str  = JSON.parse(this.resp);
      
            this.value = +this.str.data[0].cpc.value * 10
            this.nameEmail = +this.str.data[0].cpc.value 
            this.nameEmailPhone = +this.str.data[0].cpc.value * 3
        
            if(this.value == 0) {
              this.novalue = true;
              this.isLoading = false
            }
            if(this.value > 0) {
              this.novalue = false;
              this.isLoading = false
              this.afs.doc('keywords/' + this.upperCase).set({
                // subscribers: [],
                subscriberCount: 0,
                keyword:this.upperCase,
                lastPrice:this.nameEmailPhone,
                low:+this.nameEmail.toFixed(2),
                med:+this.nameEmailPhone.toFixed(2),
                high: +this.value.toFixed(2)
              },{merge:true});
            }
          }
    }
    dupeCheck(value){
     
      this.afAuth.authState.subscribe(user => {
        if (user) {
      
      this.afs.doc('users/'+ user.uid).valueChanges().subscribe(data =>{
        this.dupe = data;
       
        if(this.dupe.keywordList.includes(value) ){
       
          this.noDupe = false
        }
        else {
       
          this.noDupe = true
        }
      });
   
    
    
        }})

    }
}
