import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    data: any;
    white: boolean;
    home: boolean;
    id: any;
   
 

    constructor( private activatedRoute: ActivatedRoute,public location: Location, private router: Router, private functions: AngularFireFunctions, private afs: AngularFirestore, public auth:AuthService) {
        this.activatedRoute.params.subscribe(paramsId => {
            this.id = paramsId;
            // console.log(this.id);
        })
        this.white = false;
        this.home = false;
    }

    ngOnInit() {
        this.isHome()
        // this.data = this.afs.doc('inputs/landing').valueChanges();
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
    
        if (event instanceof NavigationStart) {
           if (event.url != this.lastPoppedUrl)
               this.yScrollStack.push(window.scrollY);
               
       } else if (event instanceof NavigationEnd) {
           if (event.url == this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else
               window.scrollTo(0, 0);
       }
     });
     this.location.subscribe((ev:PopStateEvent) => {
         this.lastPoppedUrl = ev.url;
         // console.log( this.lastPoppedUrl)
     });
     this.router.events.subscribe(e => {


        if (e instanceof NavigationEnd) {
          // console.log(this.router.url);
          // ... 
          if(this.router.url == '/home'){
            this.home = true;  
            // console.log(this.home)
          }
          else{
            this.home = false;  
            // console.log(this.home)
          }
        }
  
      });
    }

    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === '#/home' ) {
          
  
            return true;
        }
        else {
        
            return false;
        }
     
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '#/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }
}
