import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { finalize, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthService } from '../shared/services/auth.service';
import { DataService } from '../shared/services/data.service';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { PDFDocumentProxy, PdfViewerComponent } from 'ng2-pdf-viewer';
import { FormGroup, FormControl } from '@angular/forms';
import firebase from 'firebase/compat/app';
import { constants } from 'crypto';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { FontsService } from '../services/fonts.service';
import { text } from '@cloudinary/url-gen/qualifiers/source';

declare var Stripe: any;
// import Stripe from 'stripe';
// import Stripe from 'stripe';  
// const stripe = new Stripe('pk_test_51HULBDDAqwGI6PsZHiKaeoUZAtSGwz21E4A1nwe3tIotuK59koBqH4X7MdcrIeeCDJjoNGFYnrSPSRyCMIIpIxGy00K9MRqXRQ');

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    
})

export class ProfileComponent implements OnInit {

  @ViewChild(PdfViewerComponent, {static: false})
  private pdfComponent: PdfViewerComponent;
// pdfSrc = 'https://firebasestorage.googleapis.com/v0/b/subpub-app.appspot.com/o/u4ovbk3pqok?alt=media&token=87417b58-6431-4980-b0fb-a448dc5f4e14';
pdfSrc = '';
  @ViewChild('screen', { static: true }) screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('screen2', { static: true }) screen2: ElementRef;
  @ViewChild('canvas2') canvas2: ElementRef;
  @ViewChild('downloadLink2') downloadLink2: ElementRef;
  @ViewChild('canvasPDF') canvasPDF: ElementRef;
  @ViewChild('downloadLinkPDF') downloadLinkPDF: ElementRef;
  
  @Input() amount: number;
  @Input() description: string;
  @ViewChild('cardElement', {static: true}) cardElement: ElementRef;

 

  stripe; // : stripe.Stripe;
  card;

  cardErrors;
backgroundImage: any;
  model: NgbDateStruct;
  date: {year: number, month: number};
    profileForm = new FormGroup({
        firstName: new FormControl(''),
        lastName: new FormControl(''),
        email:new FormControl(''),
        phone:new FormControl(''),
        displayName: new FormControl(''),
      });
    name = new FormControl('');
    changed: boolean;
    uid: any;
  confirmation: any;
  ref: import("@angular/fire/compat/storage").AngularFireStorageReference;
  task: any;
  downloadURL: any;
  image: any;
  imageRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  images: any;
  downloadURLBackground: any;
  pdfRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  pdfs: any;
  totalPages: number;
  isPdfUploaded: boolean;
  height: any;
  width: any;
  
  file: string;
  
  rapidRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  rapidCards: any;
  public: boolean;
  image2: any;
  followerRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  followers: any;
  followerStuff: any;
  followerInfo: any;
  followerData: any[];
  followingData: any[];
  followerI: unknown;
  followingRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  following: any;
  subscriber:string;
  followersData: any[];
  followersInfo: any[];
  followingMe: boolean;
  savedRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  savedCards: any;
  saved: any;

  userDoc: import("@angular/fire/compat/firestore").AngularFirestoreDocument<unknown>;
  userInfo: any;
  cartItems: any;
  cart: any;
  stripeId: any;
  paymentHandler:any = null;
  meta: any;
  storageRef: any;
  nameRef: any;
  available: boolean;
  removeCheck: boolean;
  nameTaken:boolean;
  isName: any[];
  isTaken: any;
  newName: string;
  yours: boolean;
  customerCancel: any;
  canceling: boolean;
  fontRef: any;
  fonts: Object;

 

    constructor(public auth:AuthService, public router: Router,public afs: AngularFirestore,private calendar: NgbCalendar, public dataService: DataService,public fontService:FontsService, public functions: AngularFireFunctions, 
      private afStorage: AngularFireStorage,
      public afAuth: AngularFireAuth,) { 
        this.canceling = false;
        this.yours = false
        this.changed = false
        const db = firebase.firestore()
       this.public = false;
       this.changed = true
      dataService.mySubscriptions()

      
        this.profileForm.valueChanges.subscribe(value => {
          const inputName = value.displayName.replace(/[^A-Z0-9]/ig, "");
          
             const newName = '@'+inputName.toLowerCase()
            
           this.nameTaken;

           this.afs.collection('users/', ref=>ref.where("displayName", "==",  newName)).valueChanges().subscribe(name =>{
             this.isName = name;
           this.yours = false
             this.isTaken = this.isName[0]?.displayName
             if( this.auth.profile.displayName == newName ){
             
              this.yours = true
            } 
           
             if(this.isTaken == newName ){
              this.nameTaken = true;
          
            
              this.changed = false
             }
             if(this.isTaken != newName){
              this.nameTaken = false;
              this.changed = true;
             }
           })
           const db = firebase.firestore();

     
           
          });
          this.afAuth.authState.subscribe(async user => {
            if(user) {
            
            
              this.followerRef = this.afs.collection('users/'+ user.uid + '/followers')
              this.followers = this.followerRef.snapshotChanges().pipe(
                map(actions => actions.map(a => {
                  const data = a.payload.doc.data();
                  const id = a.payload.doc.id;
                  return { id, ...data };
                }))
              );
              this.followingRef = this.afs.collection('users/'+ user.uid + '/following')
              this.following = this.followingRef.snapshotChanges().pipe(
                map(actions => actions.map(a => {
                  const data = a.payload.doc.data();
                  const id = a.payload.doc.id;
                  return { id, ...data };
                }))
              );
              
             
             
          this.imageRef = this.afs.collection('images',ref =>ref.where('uid','==', user.uid))
          this.images = this.imageRef.snapshotChanges().pipe(
            map(actions => actions.map(a => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
    
              return { id, ...data };
            }))
          );
          this.pdfRef = this.afs.collection('PDFs',ref =>ref.where('uid','==', user.uid))
          this.pdfs = this.pdfRef.snapshotChanges().pipe(
            map(actions => actions.map(a => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
             
              return { id, ...data };
            }))
          );
          this.rapidRef = this.afs.collection('rapidCards',ref =>ref.where('uid','==', user.uid))
          this.rapidCards = this.rapidRef.snapshotChanges().pipe(
            map(actions => actions.map(a => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
             
              return { id, ...data };
            }))
          );
          this.savedRef = this.afs.collection('savedImages',ref =>ref.where('uid','==', user.uid))
          this.saved = this.savedRef.snapshotChanges().pipe(
            map(actions => actions.map(a => {
              const data = a.payload.doc.data();
              const id = a.payload.doc.id;
             
              return { id, ...data };
            }))
          );
            }})
           
            
            
    }

    startNew(id){

      this.router.navigateByUrl('/new/'+ id)
    }
    upgrade(id){
      this.router.navigateByUrl('/upgrade/'+ id)
    }
    unfollow(id, i){
      const increment = firebase.firestore.FieldValue.increment(-1);
  
      this.followerData.splice(i,1)
      this.afs.doc('users/'+ id ).update({
        followers:firebase.firestore.FieldValue.arrayRemove(this.auth.profile.uid),
        followerCount: increment
      })
      this.afs.doc('users/'+this.auth.profile.uid).update({
       following:firebase.firestore.FieldValue.arrayRemove(id),
       followingCount: increment
      })
       }
       followBack(id,i){
        const increment = firebase.firestore.FieldValue.increment(1);
  
        this.followerData.splice(i,1)
        this.afs.doc('users/'+ id ).update({
          followers:firebase.firestore.FieldValue.arrayUnion(this.auth.profile.uid),
          followerCount: increment
        })
        this.afs.doc('users/'+this.auth.profile.uid).update({
         following:firebase.firestore.FieldValue.arrayUnion(id),
         followingCount: increment
        })
       }
       scroll(el: HTMLElement) {
        el.scrollIntoView();
    }
    ngOnInit() {
      // this.invokeStripe();
      // this.loadStripe();
      // this.stripe = Stripe('pk_test_51HULBDDAqwGI6PsZHiKaeoUZAtSGwz21E4A1nwe3tIotuK59koBqH4X7MdcrIeeCDJjoNGFYnrSPSRyCMIIpIxGy00K9MRqXRQ');
      // const elements = this.stripe.elements(); 
      // this.card = elements.create('card');
      // this.card.mount(this.cardElement.nativeElement);
      
   
      // this.card.addEventListener('change', ({ error }) => {
      //     this.cardErrors = error && error.message;
      // });

      this.afAuth.authState.subscribe(async user => {
        if(user) {
      const db = firebase.firestore()

    
      this.followerStuff =  this.afs.collection("users").doc(user.uid).valueChanges().subscribe(follow =>{
  
  
        this.followerData = []
        this.followersData = []
        const followerData : [] = [];
        this.followerInfo = follow
  
        const result : string[] = [];
        // const subscriber = {};
        this.followerInfo.following.forEach(item => {

          var docRef = db.collection("users").doc(item);
          docRef.get().then((doc) => {
              if (doc.exists) {
      
                if(doc.data().followers.includes(user.uid)){
      
                this.followerData.push(
                  {
                  firstName:doc.data().firstName,
                  lastName:doc.data().lastName,
                  email:doc.data().email,
                  phone:doc.data().phone,
                  photoURL:doc.data().photoURL,
                  uid: doc.data().uid,
                  following: true,
                  displayName: doc.data().displayName
                  }
               
                  );
                  return
                }else{
       
                  this.followerData.push(
                    {
                    firstName:doc.data().firstName,
                    lastName:doc.data().lastName,
                    email:doc.data().email,
                    phone:doc.data().phone,
                    photoURL:doc.data().photoURL,
                    uid: doc.data().uid,
                    following: false,
                    displayName: doc.data().displayName
                    }
                    );
                }
               
                return
              }
               else {
                  // doc.data() will be undefined in this case
         
              }
          }).catch((error) => {
    
          });
        
        });
      
        this.followerInfo.followers.forEach(item => {
          var docRef = db.collection("users").doc(item);
          docRef.get().then((doc) => {
              if (doc.exists) {
                  this.followersData.push(
                    {
                    firstName:doc.data().firstName,
                    lastName:doc.data().lastName,
                    email:doc.data().email,
                    phone:doc.data().phone,
                    photoURL:doc.data().photoURL,
                    uid: doc.data().uid,
                    displayName: doc.data().displayName
                    }
                    );
                    return
              }
               else {
                  // doc.data() will be undefined in this case
              
                  return
              }
          }).catch((error) => {
      
          });

        });
      })
    }})
      this.file = '';
  
    }
    loadStripe() {
     
      if(!window.document.getElementById('stripe-script')) {
        var s = window.document.createElement("script");
        s.id = "stripe-script";
        s.type = "text/javascript";
        s.src = "https://checkout.stripe.com/checkout.js";
        window.document.body.appendChild(s);
      }
    }
    chargeCustomer(){
  
      this.afAuth.authState.subscribe(async user => {
        if (user) {
          this.userDoc = this.afs.collection('users/').doc(user.uid);
          this.userInfo = this.userDoc.valueChanges()
          const userRef = this.afs.doc('users/'+ user.uid);
          userRef.update({
            processing: true
          })
         
          this.cartItems = this.cart.subscribe(async (data) => { 
            this.stripeId = data
         
            const fun = this.functions.httpsCallable('stripeCreateCharge');
            this.confirmation =  await fun({email:user.email, uid: user.uid }).toPromise()
         
           const userRef = this.afs.doc('users/'+ user.uid);
           userRef.update({
             processing: false
           })
          
       
       })
    
        }})
    }
    gotofollower(uid){
      this.router.navigate[`#/list/:${uid}`]
      this.router.navigateByUrl('/list/'+ uid)
    }
  makePublic(id) {
    this.afs.doc('images/' + id).update({
      public: true
    })
  }
  makePrivate(id) {
    this.afs.doc('images/' + id).update({
      public: false
    })
  }
    

    go(id){
      this.router.navigate[`#/img/:${id}`]
      this.router.navigateByUrl('/img/'+ id)
    }
    goLocked(id){
      
      this.router.navigate[`#/imagelock/:${id}`]
      this.router.navigateByUrl('/imagelock/'+ id)
    }
    goUnLock(id){
  this.afs.collection('images').doc(id).update({
    locked:false
  })
    }
    goToPage(id){
      this.router.navigateByUrl('/list/'+ id)
    }
    billing(id){
      this.router.navigateByUrl('/billing/'+ id)
    }
    gopdf(id){
      this.router.navigate[`#/pdf/:${id}`]
      this.router.navigateByUrl('/pdf/'+ id)
    }
   
    login() {
        // this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
        this.router.navigate['landing']
      }
      updateName(value) {
        this.name.setValue(value);
      }

      async cancel(id, uid){
        Swal.fire({
          title: 'Are you sure?',
          text: "Your Co-Brandable assets will not be available to the public, and you will not be able to save.  However, you will still be able to download and Co-Brand with others.",
          icon: 'error',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, I want to cancel:)'
        }).then(async (result) => {
          this.canceling = true;
          if (result.isConfirmed) {
            const fun = this.functions.httpsCallable('cancelSubscription');
            this.customerCancel = await fun({ subId: id, user:uid }).toPromise();
            console.log(this.customerCancel)
            if(this.customerCancel){
              this.canceling = false;
              this.afs.doc('users/'+ uid).update({
                status:'canceled'
              })
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              
              })
              
              Toast.fire({
                icon: 'success',
                title: 'Your account has been canceled.'
              })
            }
          }
          
        })
     
      }



      onSubmit(value) {
        // // console.log(this.isTaken)
        
          if(this.profileForm.value.firstName != ''){
        
              this.afs.collection('users').doc(this.auth.profile.uid).set({
                firstName: this.profileForm.value.firstName,
          
              }, { merge: true})
          }
          if(this.profileForm.value.lastName != ''){
       
            this.afs.collection('users').doc(this.auth.profile.uid).set({
              lastName: this.profileForm.value.lastName,
        
            }, { merge: true})
        }
        if(this.profileForm.value.email != ''){
this.auth.updateEmail(this.profileForm.value.email)
       
        }
        if(this.profileForm.value.phone != ''){
       
            this.afs.collection('users').doc(this.auth.profile.uid).set({
              phone: this.profileForm.value.phone,
        
            }, { merge: true})
        }
        if(this.profileForm.value.displayName != ''){
          const inputName = this.profileForm.value.displayName.replace(/[^A-Z0-9]/ig, "");
          const newName = inputName.toLowerCase()
            this.afs.collection('users').doc(this.auth.profile.uid).set({
        displayName: '@'+ newName,
  
      }, { merge: true}).then();{
        this.nameTaken = false;
          this.yours = true
        Swal.fire({
          icon: 'success',
          title: 'Username changed..'
        }).then();{
          
        }
      }
      
      }
  
      }
      async addPhoto(id,downloadURL,user, active){
        const db = firebase.firestore();
       console.log(user)
      if (active == 'active'){
        const {value: file} = await Swal.fire({
          title: 'Select image',
          input: 'file',
          inputAttributes: {
            'accept': 'image/*',
            'aria-label': 'Upload your foreground image'
          }
        })
        
        if (file) {
          const reader = new FileReader
          reader.onload = (e) => {
          
            const id = Math.random().toString(36).substring(2);
                  this.ref = this.afStorage.ref(id);
                  this.task = this.ref.put(file);
                  this.task.snapshotChanges().pipe(
                    finalize(() => {
                      this.downloadURL = this.ref.getDownloadURL()
                      this.meta =this.ref.getMetadata().toPromise().then((metadata) => {
                        // Metadata now contains the metadata for 'images/forest.jpg'
                       
                        const increment = firebase.firestore.FieldValue.increment(metadata.size);
                        this.downloadURL.subscribe(url =>{
                          (this.image2 = url)
                   this.afs.collection('users/').doc(user).update({
                     storage:increment
                   })
                          const foodRef = this.afs.collection('images/');
                          foodRef.add({
                        
                           photoURL: url,
                           uid: user,
                           timeStamp: Date.now(),
                           profileImg: this.auth.profile.photoURL || '',
                           displayName:this.auth.profile.displayName || '',
                           size:increment,

                          })
                        } );
                      })
                      .catch((error) => {
                        // Uh-oh, an error occurred!
                      });
                     
                     
                    
                    }),
                    
                  ).subscribe() 
                
          }
          
          reader.readAsDataURL(file)
        }
      
      }
      else{
      
     
        Swal.fire({
          title: 'Note!',
          text: 'You need to have an ACTIVE billing account to save and create Co-brandable images.  Sign up here!',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, I want to upgrade!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.router.navigateByUrl('/upgrade/'+ user)
          }
          
        })
      }
        
     
       
       }
      async editPhotoPersonal(id,downloadURL,user){

        this.afAuth.authState.subscribe(async user => {
            if(user) {
       
         const {value: file} = await Swal.fire({
          title: 'Select image',
          input: 'file',
          inputAttributes: {
            'accept': 'image/*',
            'aria-label': 'Upload your foreground image'
          }
        })
        
        if (file) {
          const reader = new FileReader
          reader.onload = (e) => {
          
            const id = Math.random().toString(36).substring(2);
                  this.ref = this.afStorage.ref(id);
                  this.task = this.ref.put(file);
                  this.task.snapshotChanges().pipe(
                    finalize(() => {
                      this.downloadURL = this.ref.getDownloadURL()
                      this.downloadURL.subscribe(url =>{
                        (this.image = url)
                 
                        const foodRef = this.afs.doc('users/'+ user.uid);
                        foodRef.update({
                      
                         photoURL: url,
                      
                        
                        })
                      } );
                    
                    }),
                    
                  ).subscribe() 
                
          }
          
          reader.readAsDataURL(file)
        }
       }
       
       })
       
       }
     
       async editPhoto(id,downloadURL,user){

        this.afAuth.authState.subscribe(async user => {
            if(user) {
       
         const {value: file} = await Swal.fire({
          title: 'Select image',
          input: 'file',
          inputAttributes: {
            'accept': 'image/*',
            'aria-label': 'Upload your foreground image'
          }
        })
        
        if (file) {
          const reader = new FileReader
          reader.onload = (e) => {
          
            const id = Math.random().toString(36).substring(2);
                  this.ref = this.afStorage.ref(id);
                  this.task = this.ref.put(file);
                  this.task.snapshotChanges().pipe(
                    finalize(() => {
                      this.downloadURL = this.ref.getDownloadURL()
                      this.downloadURL.subscribe(url =>{
                        (this.image = url)
                 
                        // const foodRef = this.afs.doc('users/'+ user.uid);
                        // foodRef.update({
                      
                        //  photoURL: url,
                        
                        // })
                      } );
                    
                    }),
                    
                  ).subscribe() 
                
          }
          
          reader.readAsDataURL(file)
        }
       }
       else {

        const {value: file} = await Swal.fire({
          title: 'Select image',
          input: 'file',
          inputAttributes: {
            'accept': 'image/*',
            'aria-label': 'Upload your foreground image'
          }
        })
        
        if (file) {
          const reader = new FileReader
          reader.onload = (e) => {
          
            const id = Math.random().toString(36).substring(2);
                  this.ref = this.afStorage.ref(id);
                  this.task = this.ref.put(file);
                  this.task.snapshotChanges().pipe(
                    finalize(() => {
                      this.downloadURL = this.ref.getDownloadURL()
                      this.downloadURL.subscribe(url =>{
                        (this.image = url)
                 
                        // const foodRef = this.afs.doc('users/'+ user.uid);
                        // foodRef.update({
                      
                        //  photoURL: url,
                        
                        // })
                      } );
                    
                    }),
                    
                  ).subscribe() 
                
          }
          
          reader.readAsDataURL(file)
        }
       }
       })
       
       }
  
        async editPDF(id,downloadURLBackground,user){

        this.afAuth.authState.subscribe(async user => {
            if(user) {
       
         const {value: file} = await Swal.fire({
          title: 'Select background image',
          input: 'file',
          inputAttributes: {
            'accept': 'application/pdf',
            'aria-label': 'Upload your background image'
          }
        })
        
        if (file) {
          const reader = new FileReader
          reader.onload = (e) => {
     
            const id = Math.random().toString(36).substring(2);
                  this.ref = this.afStorage.ref(id);
                  this.task = this.ref.put(file);
                  this.task.snapshotChanges().pipe(
                    finalize(() => {
                      this.downloadURLBackground = this.ref.getDownloadURL()
                      this.downloadURLBackground.subscribe(url =>{
                        (this.backgroundImage = url)
                        this.pdfSrc = url
                        
                      //   const foodRef = this.afs.collection('PDFs/');
                      //   foodRef.add({
                      
                      //    pdfURL: url,
                      // uid: user.uid
                        
                      //   })
                      } );
                    
                    }),
                    
                  ).subscribe() 
                
          }
          
          reader.readAsDataURL(file)
        }
       }
        })
        }
        public uploadFile(event:any) {
          let $img: any = document.querySelector('#upload-doc');
          if(event.target.files[0].type == 'application/pdf'){
            if (typeof (FileReader) !== 'undefined') {
              let reader = new FileReader();
              reader.onload = (e: any) => {
                this.pdfSrc = e.target.result;
              };
              this.isPdfUploaded = true;
              reader.readAsArrayBuffer($img.files[0]);
            }
          } else{
            alert('Please upload pdf file')
          }
       }
        afterLoadComplete(pdf: PDFDocumentProxy) {
          this.totalPages = pdf.numPages;
        }
        pageRendered(ev) {
          const width = ev.source.div.clientWidth;
          const height = ev.source.div.clientHeight;
          const ratio = width / height;
          const targetHeight = 384;
          const requiredWidth = ratio * targetHeight;
          const scale = requiredWidth / width;
          // this.setState('zoom', scale);
        }
        onPdfLoaded($event){

          html2canvas(this.screen2.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            windowWidth: document.documentElement.offsetWidth,
            windowHeight: document.documentElement.offsetHeight }).then(canvas2 => {
            
            this.canvas2.nativeElement.src = canvas2.toDataURL();
            this.downloadLink2.nativeElement.href = canvas2.toDataURL('image/png');
         
            // this.downloadLink.nativeElement.click();

            const base = this.canvas2.nativeElement.src
       

        this.afAuth.authState.subscribe(async user => {
          if(user) {
            var metadata = {
              contentType: 'image/jpeg',
            };
            var base64data = base.replace("data:image/png;base64,", "");
        var bs = atob(base64data);
        var buffer = new ArrayBuffer(bs.length);
        var ba = new Uint8Array(buffer);
        for (var i = 0; i < bs.length; i++) {
            ba[i] = bs.charCodeAt(i);
        }
        const file = new Blob([ba], { type: "image/png" });
            const id = Math.random().toString(36).substring(2);
                  this.ref = this.afStorage.ref(id);
                  this.task = this.ref.put(file);
                  this.task.snapshotChanges().pipe(
                    finalize(() => {
                      
                      this.downloadURLBackground = this.ref.getDownloadURL()
                      this.downloadURLBackground.subscribe(url =>{
                        (this.backgroundImage = url)
                        this.pdfSrc = url
                        
                        const foodRef = this.afs.collection('images/');
                        foodRef.add({
                      
                         photoURL: url,
                         uid: user.uid
                        
                        })
                      } );
                    
                    }),
                    
                  ).subscribe() 
                  }})
          });
        
        }
        captureScreen()
        {
          html2canvas(this.screen.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
            scrollY: -window.scrollY, backgroundColor:null,
            windowWidth: document.documentElement.offsetWidth,
            windowHeight: document.documentElement.offsetHeight }).then(canvas => {
            
            this.canvas.nativeElement.src = canvas.toDataURL();
            this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
            // this.downloadLink.nativeElement.download = 'InstantCoBrand.png';
            this.downloadLink.nativeElement.click();
            const base = this.canvas.nativeElement.src
            this.afAuth.authState.subscribe(async user => {
              if(user) {
                var metadata = {
                  contentType: 'image/jpeg',
                };
                var base64data = base.replace("data:image/png;base64,", "");
            var bs = atob(base64data);
            var buffer = new ArrayBuffer(bs.length);
            var ba = new Uint8Array(buffer);
            for (var i = 0; i < bs.length; i++) {
                ba[i] = bs.charCodeAt(i);
            }
            const file = new Blob([ba], { type: "image/png" });
                const id = Math.random().toString(36).substring(2);
                      this.ref = this.afStorage.ref(id);
                      this.task = this.ref.put(file);
                      this.task.snapshotChanges().pipe(
                        finalize(() => {
                          
                          this.downloadURLBackground = this.ref.getDownloadURL()
                          this.downloadURLBackground.subscribe(url =>{
                            (this.backgroundImage = url)
                            this.pdfSrc = url
                            
                            const foodRef = this.afs.collection('rapidCards/');
                            foodRef.add({
                          
                             coBrandCard: url,
                             rapidCard: false,
                             uid: user.uid
                            
                            })
                          } );
                        
                        }),
                        
                      ).subscribe() 
                      }})
          });
        }
        captureScreen2()
    {
      html2canvas(this.screen2.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight }).then(canvas2 => {
        
        this.canvas2.nativeElement.src = canvas2.toDataURL();
        this.downloadLink2.nativeElement.href = canvas2.toDataURL('image/png');
        this.downloadLink2.nativeElement.download2 = 'InstantCoBrand.png';
        this.downloadLink2.nativeElement.click();
        // const doc = new jsPDF({
        //   orientation: "landscape"});

        // doc.addImage( this.canvas.nativeElement.src,'PNG', 0,0,0,0);
        // doc.save("InstantCoBrand.pdf");
      });
    
    }
    delete(id){
      const foodRef = this.afs.doc('rapidCards/'+ id);
      foodRef.delete()
     }
     deleteImage(id,size,url){
      const db = firebase.firestore();
      const decrement = firebase.firestore.FieldValue.increment(-size);
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
          const foodRef = this.afs.doc('images/'+ id);
      foodRef.delete()
      this.afs.collection('users/').doc(this.auth.profile.uid).update({
        storage:decrement
      }).then();{
        return this.afStorage.storage.refFromURL(url).delete();
      }
        }
      })
    
     
      
     
     }
     deleteSavedImage(id,size,url){
      // const foodRef = this.afs.doc('savedImages/'+ id);
      // foodRef.delete()
      const db = firebase.firestore();
      const decrement = firebase.firestore.FieldValue.increment(-size);
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
          const foodRef = this.afs.doc('savedImages/'+ id);
      foodRef.delete()
      this.afs.collection('users/').doc(this.auth.profile.uid).update({
        storage:decrement
      }).then();{
        return this.afStorage.storage.refFromURL(url).delete();
      }
        }
      })
     }

     notActive(user){
      Swal.fire({
        title: 'Note!',
        text: 'You need to have an ACTIVE billing account to save and create Co-brandable images.  Sign up here!',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, I want to upgrade!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigateByUrl('/upgrade/'+ user)
        } 
     })
    }

    async addTag(id, count){
if (count >= 5){
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 3000,
    
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  
  Toast.fire({
    icon: 'error',
    title: 'There is a 5 tag limit for each image.'
  })
}
else{
  const increment = firebase.firestore.FieldValue.increment(1);
      const { value: text } = await Swal.fire({
        title: 'Add A Tag',
        input: 'text',
        inputLabel: 'An Image Tag',
        inputPlaceholder: 'Add Tag Here'
      })
      
      if (text) {
        Swal.fire(`Tag Added: ${text}`)
        this.afs.collection('images').doc(id).update({
          tags: firebase.firestore.FieldValue.arrayUnion(text),
          tagCount: increment
        })
      }
}
    

    }
 removeTag(id,tag){
  const increment = firebase.firestore.FieldValue.increment(-1);
  this.afs.collection('images').doc(id).update({
    tags: firebase.firestore.FieldValue.arrayRemove(tag),
    tagCount:increment
  })
 }
}

// { "type": "file", "bucket": "subpub-app.appspot.com", "generation": "1649946961561993", "metageneration": "1", "fullPath": "kr4myyh9lab", "name": "kr4myyh9lab", "size": 53674, "timeCreated": "2022-04-14T14:36:01.587Z", "updated": "2022-04-14T14:36:01.587Z", "md5Hash": "DrBXsFJv2vy7UuvaOOAcPA==", "contentDisposition": "inline; filename*=utf-8''kr4myyh9lab", "contentEncoding": "identity", "contentType": "image/png" }