import { ElementRef, Input } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

import Swal from 'sweetalert2';
import { AuthService } from '../shared/services/auth.service';
import { DataService } from '../shared/services/data.service';
import firebase from 'firebase/compat/app';
declare var Stripe: any;

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.css']
})
export class UpgradeComponent implements OnInit {
    
  @Input() amount: number;
  @Input() description: string;
  @ViewChild('cardElement', {static: true}) cardElement: ElementRef;

 

  stripe; // : stripe.Stripe;
  card;

  cardErrors;
  userDoc: import("@angular/fire/compat/firestore").AngularFirestoreDocument<unknown>;
  userInfo: any;
  cartItems: any;
  cart: any;
  stripeId: any;
  confirmation: any;
  source: any;
  coupon: string;
  price: string;
  processing: boolean;

  constructor(public auth:AuthService, public router: Router,public afs: AngularFirestore,private calendar: NgbCalendar, public dataService: DataService, public functions: AngularFireFunctions, 
    private afStorage: AngularFireStorage,
    public afAuth: AngularFireAuth,) { 
      this.processing = false
    }

  ngOnInit(): void {
    this.loadStripe();
    this.stripe = Stripe('pk_live_51L1VZeLB4UQixE7dKdtzSTu1Y8djzALcwnToxZTVGGh30dA3njGgUYWT4BAhvhpOR1bjpo3IaQhAclYJf9u38q9F00tN82peqd');
    const elements = this.stripe.elements(); 
    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);
    
 
    this.card.addEventListener('change', ({ error }) => {
        this.cardErrors = error && error.message;
    });
  }
  loadStripe() {
     
    if(!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      window.document.body.appendChild(s);
    }
  }
  chargeCustomer(){
  
    this.afAuth.authState.subscribe(async user => {
      if (user) {
        this.userDoc = this.afs.collection('users/').doc(user.uid);
        this.userInfo = this.userDoc.valueChanges()
        const userRef = this.afs.doc('users/'+ user.uid);
        userRef.update({
          processing: true
        })
       
        this.cartItems = this.cart.subscribe(async (data) => { 
          this.stripeId = data
          // console.log(this.stripeId.stripeCustomerId)
          const fun = this.functions.httpsCallable('stripeCreateCharge');
          this.confirmation =  await fun({email:user.email, uid: user.uid }).toPromise()
         console.log(this.confirmation)
         const userRef = this.afs.doc('users/'+ user.uid);
         userRef.update({
           processing: false
         })
        
     
     })
  
      }})
  }

  SuccessNotification(){
    const db = firebase.firestore();
        const increment = firebase.firestore.FieldValue.increment(1);
 
    this.afAuth.authState.subscribe(async user => {
      if (user) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      // didOpen: (toast) => {
      //   toast.addEventListener('mouseenter', Swal.stopTimer)
      //   toast.addEventListener('mouseleave', Swal.resumeTimer)
      // }
    })
    
   Swal.fire({
      icon: 'success',
      title: 'Payment was successful!'
    })
    this.router.navigate(['user-profile/'])
  }})
  }
  FailNotification(errorCode){
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      // didOpen: (toast) => {
      //   toast.addEventListener('mouseenter', Swal.stopTimer)
      //   toast.addEventListener('mouseleave', Swal.resumeTimer)
      // }
    })
    
    Swal.fire({
      icon: 'error',
      title: 'Something went wrong...' + errorCode
    })
  }




async handleForm(e, stripeCustomerId, user, usage) {
  // console.log(stripeCustomerId)
  console.log(stripeCustomerId, user)
  e.preventDefault();
const userId = user
  const { source, error } = await this.stripe.createSource(this.card);
// // console.log('Source = '+ source.id, 'card = ' + this.card, 'amount = ' + price)
  if (error) {
    // Inform the customer that there was an error.
    this.cardErrors = error.message;
  } else {
    const stripePrice = 'price_1L1ucbLB4UQixE7dEl1pBqE7'
    // Send the token to your server.
  
this.processing = true
    const userRef = this.afs.doc('users/'+ userId);
    userRef.update({
      processing: true
    })
    const fun = this.functions.httpsCallable('stripeCreateSubscription');
    this.confirmation = await fun({ source: source.id, uid: user, amount: stripePrice, customer: stripeCustomerId, usage:usage}).toPromise();
    console.log(this.confirmation.items)
  const errorCode = this.confirmation.code

 
    if (this.confirmation){
      this.processing = false;
      const userRef = this.afs.doc('users/'+ userId);
      userRef.update({
        processing: false,
       active: true,
        source: source.id,

    
      })
    
      
      
 
      this.SuccessNotification()
    }
      if (this.confirmation.statusCode){
      
        const userRef = this.afs.doc('users/'+ userId);
        userRef.update({
          processing: false,
          paid: false,
          paymentError: this.confirmation.code
        })
        this.FailNotification(errorCode)
      }
    }
   

  }

}
