import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FontsService {

  constructor(private httpClient: HttpClient) { 
   this.getDataFromServer
  }
  getDataFromServer() {
    const params = {
      'key': 'AIzaSyA8bgCLOM2E5uq2zAHKbcs0hICjwODLQrg'
      // param2: value2
    }
    const url = 'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyA8bgCLOM2E5uq2zAHKbcs0hICjwODLQrg'

    // { params: params } is the same as { params } 
    // look for es6 object literal to read more
    return this.httpClient.get(url, { params }).toPromise(
    
    );
  }
}
