import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AuthService } from '../shared/services/auth.service';
import { DataService } from '../shared/services/data.service';
import firebase from 'firebase/compat/app';
declare var Stripe: any;

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {
  @Input() amount: number;
  @Input() description: string;
  @ViewChild('cardElement', {static: true}) cardElement: ElementRef;

 

  stripe; // : stripe.Stripe;
  card;

  cardErrors;
  userDoc: import("@angular/fire/compat/firestore").AngularFirestoreDocument<unknown>;
  userInfo: any;
  cartItems: any;
  cart: any;
  stripeId: any;
  confirmation: any;
  source: any;
  coupon: string;
  price: string;
  customerConfirmation: any;
  cusId: any;
  updating: boolean;

  id: any;
  constructor(
        public auth:AuthService,
        public router: Router,
        public afs: AngularFirestore,
        private calendar: NgbCalendar,
        public dataService: DataService,
        public functions: AngularFireFunctions, 
        private afStorage: AngularFireStorage,
        public afAuth: AngularFireAuth,
        private activatedRoute: ActivatedRoute
    ) {
      this.activatedRoute.params.subscribe(paramsId => {
        this.id = paramsId.id;})
     }
  ngOnInit(): void {

  

    this.loadStripe();
    this.stripe = Stripe('pk_test_51KnoLoFkKqeixSl2wihM8obl0gE3qHPTGSbyHcolS3hCcs4gkcyKxi4zQ2SIuq74fp8DAFnoPWM1KyZ6chhBEPnp00BCGuDr59');
    const elements = this.stripe.elements(); 
    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);
    
 
    this.card.addEventListener('change', ({ error }) => {
        this.cardErrors = error && error.message;
    });
  }

  
  ngAfterInit(){
  
  }
  loadStripe() {
     
    if(!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      window.document.body.appendChild(s);
    }
  }
  async handleForm(e, stripeCustomerId, user, usage, oldSource, firstName, lastName) {

    e.preventDefault();
  const userId = user
  this.stripe.createPaymentMethod({
    type: 'card',
    card: this.card,
    billing_details: {
      name: firstName + ' ' + lastName,
    },
  })
  .then(async result  => {
    // Handle result.error or result.paymentMethod
    console.log(result.paymentMethod.id)
    // if (result.error) {
    //   // Inform the customer that there was an error.
    //   this.cardErrors = result.error.message;
    // } else {
      const stripePrice = 'price_1KqnJUFkKqeixSl2pvrMkAA9'
      // Send the token to your server.

  
      const userRef = this.afs.doc('users/'+ userId);
      userRef.update({
        updating: true,
 
      })
      const fun = this.functions.httpsCallable('updateSource');
      this.confirmation = await fun({ source: result.paymentMethod.id, uid: user, customer: stripeCustomerId, oldSource:oldSource}).toPromise();
      console.log(this.confirmation)
    const errorCode = this.confirmation
    this.afs.doc('users/'+ userId).update({
      source: result.paymentMethod.id,
      updating:false
    })
   
      if (this.confirmation){
       
      
        
        
   
        this.SuccessNotification('Your card has been updated!')
        this.router.navigate(['user-profile/'])
      }
        if (this.confirmation.statusCode){
        
          const userRef = this.afs.doc('users/'+ userId);
          userRef.update({
            sourceError: this.confirmation.code
          })
          this.FailNotification(errorCode)
        }
      
  });

  
     
  
    }
    SuccessNotification(string){
      const db = firebase.firestore();
          const increment = firebase.firestore.FieldValue.increment(1);
   
      this.afAuth.authState.subscribe(async user => {
        if (user) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        // didOpen: (toast) => {
        //   toast.addEventListener('mouseenter', Swal.stopTimer)
        //   toast.addEventListener('mouseleave', Swal.resumeTimer)
        // }
      })
      
     Swal.fire({
        icon: 'success',
        title: string
      })
    }})
    }
    FailNotification(errorCode){
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        // didOpen: (toast) => {
        //   toast.addEventListener('mouseenter', Swal.stopTimer)
        //   toast.addEventListener('mouseleave', Swal.resumeTimer)
        // }
      })
      
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong...' + errorCode
      })
    }
  
}
