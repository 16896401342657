import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';


import { LoginComponent } from './login/login.component';
// import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
// import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideFunctions,getFunctions } from '@angular/fire/functions';
import { providePerformance,getPerformance } from '@angular/fire/performance';
import { provideStorage,getStorage } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { ForBusinessComponent } from './for-business/for-business.component';
import { ForConsumersComponent } from './for-consumers/for-consumers.component';
import { BusinessFoundationComponent } from './business-foundation/business-foundation.component';
import { ConsumerFoundationComponent } from './consumer-foundation/consumer-foundation.component';
import { CompensationComponent } from './compensation/compensation.component';
import { B2bComponent } from './b2b/b2b.component';
import { ForgotComponent } from './forgot/forgot.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImgComponent } from './img/img.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { ListComponent } from './list/list.component';
import { ColorPickerModule } from 'ngx-color-picker';
// import { DragDropModule } from '@angular/cdk/drag-drop';
import { ColorChromeModule } from 'ngx-color/chrome';
import { TestComponent } from './test/test.component';
import { ToolComponent } from './tool/tool.component';

import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import { BrandComponent } from './brand/brand.component';
import { InstantBrandComponent } from './instant-brand/instant-brand.component';
import { NewComponent } from './new/new.component';

import { CloudComponent } from './cloud/cloud.component';
import { HttpClientModule } from '@angular/common/http';
import { FeedComponent } from './feed/feed.component';
import { DiscoveryComponent } from './discovery/discovery.component';
import { MomentModule } from 'ngx-moment';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { TwitterComponent } from './twitter/twitter.component';
import { BillingComponent } from './billing/billing.component';
import { PricingComponent } from './pricing/pricing.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { ExampleComponent } from './example/example.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CopywrightComponent } from './copywright/copywright.component';
import { LockComponent } from './lock/lock.component';
import { LockBrandComponent } from './lock-brand/lock-brand.component';
import { ImagelockComponent } from './imagelock/imagelock.component';
import { LockUserComponent } from './lock-user/lock-user.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LandingComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    ForBusinessComponent,
    ForConsumersComponent,
    BusinessFoundationComponent,
    ConsumerFoundationComponent,
    CompensationComponent,
    B2bComponent,
    ForgotComponent,
    ImgComponent,
   
    HomeComponent,

        ListComponent,
        TestComponent,
        ToolComponent,

        BrandComponent,
        InstantBrandComponent,
        NewComponent,
        CloudComponent,
        FeedComponent,
        DiscoveryComponent,
        UpgradeComponent,
        TwitterComponent,
        BillingComponent,
        PricingComponent,
        EnterpriseComponent,
        ExampleComponent,
        PrivacyComponent,
        CopywrightComponent,
        LockComponent,
        LockBrandComponent,
        ImagelockComponent,
        LockUserComponent

  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    NgbModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    MomentModule,
    // HomeModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    // AngularFirestoreModule,
    // AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    // AngularFireStorageModule,
    // AngularFireStorageModule,
    // AngularFireFunctionsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    CdkAccordionModule,
    PdfViewerModule,
    ColorPickerModule,
    ColorChromeModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSelectModule

  ],
  providers: [ ScreenTrackingService,  UserTrackingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
