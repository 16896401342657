import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { finalize, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthService } from '../shared/services/auth.service';
import { DataService } from '../shared/services/data.service';
import { CdkDragEnd, CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter, CdkDragExit } from '@angular/cdk/drag-drop';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {DragDropModule} from '@angular/cdk/drag-drop';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { convertPdfToPng } from 'convert-pdf-png';
import { ColorEvent } from 'ngx-color';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  currentItem = './assets/img/brand/InstantCoBrand.png';
  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  expandedIndex = 0;
 
  qtd:any[];
  basket: any;
  title: string;
  newItems: string[];
  image2: any;
  image3: any;
  text1Color: string;
  text1Weight: number;
  text1Size: number;
  text1: string;
  text2Color: string;
  text2Weight: number;
  text2Size: number;
  text2: string;
  text3Color: string;
  text3Weight: number;
  text3Size: number;
  text3: string;
  backgroundWidth1: number;
  backgroundHeight1: number;
  cornerRadius1: number;
  backgroundColor1: string;
  backgroundWidth2: number;
  backgroundHeight2: number;
  cornerRadius2: number;
  backgroundColor2: string;
  backgroundWidth3: number;
  backgroundHeight3: number;
  cornerRadius3: number;
  backgroundColor3: string;
  background3Z: number;
  background2Z: number;
  background1Z: number;
  avatar1Z: number;
  avatar2Z: number;
  avatar3Z: number;
  text1Z: number;
  text2Z: number;
  text3Z: number;
  isPdfUploaded: boolean;
  totalPages: number;
  newPDF: string;
  newpdfSrc: any;
  rapidCardSize: any;
  rapidCardZ: number;
  rapidRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  rapidCards: any;
  newBackground: boolean;
  backgroundTransparancy1: any;
  backgroundRotate1: any;
  backgroundTransparancy2: any;
  backgroundRotate2: any;
  backgroundTransparancy3: any;
  backgroundRotate3: any;
  nums: string[];
  listItems: any;
  total: number;
  letters = '0123456789ABCDE';
  color = '#';
  

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
  // pdfSrc = 'https://firebasestorage.googleapis.com/v0/b/subpub-app.appspot.com/o/u4ovbk3pqok?alt=media&token=87417b58-6431-4980-b0fb-a448dc5f4e14';
  pdfSrc = 'https://firebasestorage.googleapis.com/v0/b/subpub-app.appspot.com/o/u4ovbk3pqok?alt=media&token=87417b58-6431-4980-b0fb-a448dc5f4e14';
  @ViewChild('screen', { static: true }) screen: ElementRef;
  // @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('screen2', { static: true }) screen2: ElementRef;
  // @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas2') canvas2: ElementRef;
  @ViewChild('downloadLink2') downloadLink2: ElementRef;
  @ViewChild('canvasPDF') canvasPDF: ElementRef;
  @ViewChild('downloadLinkPDF') downloadLinkPDF: ElementRef;
  
    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;
    ref: import("@angular/fire/compat/storage").AngularFireStorageReference;
    task: any;
    downloadURL: any;
    image: any;
    backgroundImage: any;
    titleColor: string;
    titleWeight: number;
    titleSize: number;
    bodyColor: string;
    bodyWeight: number;
    bodySize: number;
    
    state: string;
    xPosition: number;
    yPosition: number;
    position = '';
    downloadURLBackground: any;
  file: string;
  backgroundWidth: any;
  backgroundHeight: any;
  backgroundColor: any;
  cornerRadius: any;
  avatarCorner1:any;
  avatarCorner2:any;
  avatarCorner3:any;
  avatarSize: number;
  avatarSize2: number;
  avatarSize3: number;
  arrayOne: any;
  arrayTwo: any;
  filePath: string;
  myForm: FormGroup;
  productForm: FormGroup;
    constructor(public auth:AuthService, 
        public router: Router,
        public afs: AngularFirestore,
        private calendar: NgbCalendar, 
        public dataService: DataService, 
        public functions: AngularFireFunctions,
        private afStorage: AngularFireStorage,
        public afAuth: AngularFireAuth,
        public fb: FormBuilder
 ) {
  this.productForm = this.fb.group({
    name: '',
    quantities: this.fb.array([]) ,
  });
  this.myForm = this.fb.group({
    img: [null],
    filename: ['']
  })
   this.backgroundImage = 'https://firebasestorage.googleapis.com/v0/b/subpub-app.appspot.com/o/u4ovbk3pqok?alt=media&token=87417b58-6431-4980-b0fb-a448dc5f4e14';
  this.arrayOne = ['https://firebasestorage.googleapis.com/v0/b/subpub-app.appspot.com/o/600076_WK_Zoom_Background%201.jpg?alt=media&token=a3c33f75-9867-4816-a13e-92c261c884e9']
  this.afAuth.authState.subscribe(async user => {
    if(user) {
  
  this.rapidRef = this.afs.collection('rapidCards',ref =>ref.where('uid','==', user.uid))
  this.rapidCards = this.rapidRef.snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data();
      const id = a.payload.doc.id;
     
      return { id, ...data };
    }))
  );
    }})
         }

    ngOnInit() {
     
        this.file = '';
       
      }
      scroll(el: HTMLElement) {
        el.scrollIntoView();
    }
     
       
      onSubmit() {
        console.log(this.productForm.value);
      }
      handleChange($event: ColorEvent) {
        console.log($event.color);
        // color = {
        //   hex: '#333',
        //   rgb: {
        //     r: 51,
        //     g: 51,
        //     b: 51,
        //     a: 1,
        //   },
        //   hsl: {
        //     h: 0,
        //     s: 0,
        //     l: .20,
        //     a: 1,
        //   },
        // }
      }
      showCard(){
        this.afs.doc('users/'+ this.auth.profile.uid).update({
          showCard:true
        })
      }
      removeCard(){
        this.afs.doc('users/'+ this.auth.profile.uid).update({
          showCard:false
        })
      }
 pageRendered(ev) {


		
		const width = ev.source.div.clientWidth;
		const height = ev.source.div.clientHeight;
		const ratio = width / height;
		const targetHeight = 384;
		const requiredWidth = ratio * targetHeight;
		const scale = requiredWidth / width;
		// this.setState('zoom', scale);
	}
      front(value){
        console.log(value)
       if (value == 'avatar1Z'){
         this.avatar1Z ++
      
       }
       if (value == 'avatar2Z'){
        this.avatar2Z ++
     
      }
      if (value == 'avatar3Z'){
        this.avatar3Z ++
  
      }
      if (value == 'text1Z'){
        this.text1Z ++
   
      }
      if (value == 'text2Z'){
        this.text2Z ++
 
      }
      if (value == 'text3Z'){
        this.text3Z ++
     
      }
      if (value == 'background1Z'){
        this.background1Z ++

      }
      if (value == 'background2Z'){
        this.background2Z ++
      
      }
      if (value == 'background3Z'){
        this.background3Z ++
      
      }

      }
      back(value){
        console.log(value)
       if (value == 'avatar1Z'){
         this.avatar1Z --
      
       }
       if (value == 'avatar2Z'){
        this.avatar2Z --
     
      }
      if (value == 'avatar3Z'){
        this.avatar3Z --
  
      }
      if (value == 'text1Z'){
        this.text1Z --
   
      }
      if (value == 'text2Z'){
        this.text2Z --
 
      }
      if (value == 'text3Z'){
        this.text3Z --
     
      }
      if (value == 'background1Z'){
        this.background1Z --

      }
      if (value == 'background2Z'){
        this.background2Z --
      
      }
      if (value == 'background3Z'){
        this.background3Z --
      
      }

      }


    captureScreen()
    {
      html2canvas(this.screen.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight }).then(canvas => {
        
        this.canvas.nativeElement.src = canvas.toDataURL();
        this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        this.downloadLink.nativeElement.download = 'InstantCoBrand.png';
        this.downloadLink.nativeElement.click();
        // const doc = new jsPDF({
        //   orientation: "landscape"});

        // doc.addImage( this.canvas.nativeElement.src,'PNG', 0,0,0,0);
        // doc.save("InstantCoBrand.pdf");
      });
     
    }
    captureScreen2()
    {
      html2canvas(this.screen2.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight }).then(canvas2 => {
        
        this.canvas2.nativeElement.src = canvas2.toDataURL();
        this.downloadLink2.nativeElement.href = canvas2.toDataURL('image/png');
        this.downloadLink2.nativeElement.download2 = 'InstantCoBrand.png';
        this.downloadLink2.nativeElement.click();
        // const doc = new jsPDF({
        //   orientation: "landscape"});

        // doc.addImage( this.canvas.nativeElement.src,'PNG', 0,0,0,0);
        // doc.save("InstantCoBrand.pdf");
      });
     
    }
    capturePDF()
    {
      html2canvas(this.screen.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight }).then(canvas => {
        
        this.canvas.nativeElement.src = canvas.toDataURL();
        // this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        // this.downloadLink.nativeElement.download = 'InstantCoBrand.png';
        // this.downloadLink.nativeElement.click();
        const pdf = new jsPDF({
          orientation: 'landscape',
        });
        const imgProps= pdf.getImageProperties(this.canvas.nativeElement.src);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        const marginTop = pdfHeight / 2;
        pdf.addImage(this.canvas.nativeElement.src, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('InstantCoBrand.pdf');

       
      });
     
    }
    capturePDFPortrait()
    {
      html2canvas(this.screen.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight }).then(canvas => {
        
        this.canvas.nativeElement.src = canvas.toDataURL();
        // this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        // this.downloadLink.nativeElement.download = 'InstantCoBrand.png';
        // this.downloadLink.nativeElement.click();
        const pdf = new jsPDF({
          orientation: 'portrait',
        });
        const imgProps= pdf.getImageProperties(this.canvas.nativeElement.src);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        const marginTop = pdfHeight / 2
        pdf.addImage(this.canvas.nativeElement.src, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('InstantCoBrand.pdf');

       
      });
     
    }
    upload(pdf){
      this.afAuth.authState.subscribe(async user => {
        if(user) {
    if (pdf){
        const reader = new FileReader
        reader.onload = (e) => {
        
          const id = Math.random().toString(36).substring(2);
                this.ref = this.afStorage.ref(id);
                this.task = this.ref.put(pdf);
                this.task.snapshotChanges().pipe(
                  finalize(() => {
                    this.downloadURL = this.ref.getDownloadURL()
                    this.downloadURL.subscribe(url =>{
                      ( this.pdfSrc = url, this.file = url)
                     
                      // const foodRef = this.afs.doc('users/'+ user.uid);
                      // foodRef.update({
                    
                      //  photoURL: url,
                      
                      // })
                    } );
                  
                  }),
                  
                ).subscribe() 
              
        }
        
        reader.readAsDataURL(pdf)
      }
    }
      })
    }
    async editBackgroundPhoto(id,downloadURL,user){
     
  console.log('editbg')
      this.afAuth.authState.subscribe(async user => {
          if(user) {
     
       const {value: file} = await Swal.fire({
        title: 'Select image',
        input: 'file',
        inputAttributes: {
          'accept': 'image/*',
          'aria-label': 'Upload your foreground image'
        }
      })
      
      if (file) {
        const reader = new FileReader
        reader.onload = (e) => {
        
          const id = Math.random().toString(36).substring(2);
                this.ref = this.afStorage.ref(id);
                this.task = this.ref.put(file);
                this.task.snapshotChanges().pipe(
                  finalize(() => {
                    this.downloadURL = this.ref.getDownloadURL()
                    this.downloadURL.subscribe(url =>{
                      (this.backgroundImage = url)
               console.log(this.backgroundImage,'editBackgroundPhoto')
               this.newBackground = true;
                      // const foodRef = this.afs.doc('users/'+ user.uid);
                      // foodRef.update({
                    
                      //  photoURL: url,
                      
                      // })
                    } );
                  
                  }),
                  
                ).subscribe() 
              
        }
        
        reader.readAsDataURL(file)
      }
     }
     else {

      const {value: file} = await Swal.fire({
        title: 'Select image',
        input: 'file',
        inputAttributes: {
          'accept': 'image/*',
          'aria-label': 'Upload your foreground image'
        }
      })
      
      if (file) {
        const reader = new FileReader
        reader.onload = (e) => {
        
          const id = Math.random().toString(36).substring(2);
                this.ref = this.afStorage.ref(id);
                this.task = this.ref.put(file);
                this.task.snapshotChanges().pipe(
                  finalize(() => {
                    this.downloadURL = this.ref.getDownloadURL()
                    this.downloadURL.subscribe(url =>{
                      (this.backgroundImage = url),
                     
                      this.newBackground = true;
                      console.log(this.backgroundImage)
                      // const foodRef = this.afs.doc('users/'+ user.uid);
                      // foodRef.update({
                    
                      //  photoURL: url,
                      
                      // })
                    } );
                  
                  }),
                  
                ).subscribe() 
              
        }
        
        reader.readAsDataURL(file)
      }
     }
     })
     
     }
      async editPhoto(id,downloadURL,user){

        this.afAuth.authState.subscribe(async user => {
            if(user) {
       
         const {value: file} = await Swal.fire({
          title: 'Select image',
          input: 'file',
          inputAttributes: {
            'accept': 'image/*',
            'aria-label': 'Upload your foreground image'
          }
        })
        
        if (file) {
          const reader = new FileReader
          reader.onload = (e) => {
          
            const id = Math.random().toString(36).substring(2);
                  this.ref = this.afStorage.ref(id);
                  this.task = this.ref.put(file);
                  this.task.snapshotChanges().pipe(
                    finalize(() => {
                      this.downloadURL = this.ref.getDownloadURL()
                      this.downloadURL.subscribe(url =>{
                        (this.image = url)
                 
                        // const foodRef = this.afs.doc('users/'+ user.uid);
                        // foodRef.update({
                      
                        //  photoURL: url,
                        
                        // })
                      } );
                    
                    }),
                    
                  ).subscribe() 
                
          }
          
          reader.readAsDataURL(file)
        }
       }
       else {

        const {value: file} = await Swal.fire({
          title: 'Select image',
          input: 'file',
          inputAttributes: {
            'accept': 'image/*',
            'aria-label': 'Upload your foreground image'
          }
        })
        
        if (file) {
          const reader = new FileReader
          reader.onload = (e) => {
          
            const id = Math.random().toString(36).substring(2);
                  this.ref = this.afStorage.ref(id);
                  this.task = this.ref.put(file);
                  this.task.snapshotChanges().pipe(
                    finalize(() => {
                      this.downloadURL = this.ref.getDownloadURL()
                      this.downloadURL.subscribe(url =>{
                        (this.image = url)
                 
                        // const foodRef = this.afs.doc('users/'+ user.uid);
                        // foodRef.update({
                      
                        //  photoURL: url,
                        
                        // })
                      } );
                    
                    }),
                    
                  ).subscribe() 
                
          }
          
          reader.readAsDataURL(file)
        }
       }
       })
       
       }
       async editPhoto2(id,downloadURL,user){

        this.afAuth.authState.subscribe(async user => {
            if(user) {
       
         const {value: file} = await Swal.fire({
          title: 'Select image',
          input: 'file',
          inputAttributes: {
            'accept': 'image/*',
            'aria-label': 'Upload your foreground image'
          }
        })
        
        if (file) {
          const reader = new FileReader
          reader.onload = (e) => {
          
            const id = Math.random().toString(36).substring(2);
                  this.ref = this.afStorage.ref(id);
                  this.task = this.ref.put(file);
                  this.task.snapshotChanges().pipe(
                    finalize(() => {
                      this.downloadURL = this.ref.getDownloadURL()
                      this.downloadURL.subscribe(url =>{
                        (this.image2 = url)
                 
                        // const foodRef = this.afs.doc('users/'+ user.uid);
                        // foodRef.update({
                      
                        //  photoURL: url,
                        
                        // })
                      } );
                    
                    }),
                    
                  ).subscribe() 
                
          }
          
          reader.readAsDataURL(file)
        }
       }
       else {

        const {value: file} = await Swal.fire({
          title: 'Select image',
          input: 'file',
          inputAttributes: {
            'accept': 'image/*',
            'aria-label': 'Upload your foreground image'
          }
        })
        
        if (file) {
          const reader = new FileReader
          reader.onload = (e) => {
          
            const id = Math.random().toString(36).substring(2);
                  this.ref = this.afStorage.ref(id);
                  this.task = this.ref.put(file);
                  this.task.snapshotChanges().pipe(
                    finalize(() => {
                      this.downloadURL = this.ref.getDownloadURL()
                      this.downloadURL.subscribe(url =>{
                        (this.image2 = url)
                 
                        // const foodRef = this.afs.doc('users/'+ user.uid);
                        // foodRef.update({
                      
                        //  photoURL: url,
                        
                        // })
                      } );
                    
                    }),
                    
                  ).subscribe() 
                
          }
          
          reader.readAsDataURL(file)
        }
       }
       })
       
       }
       async editPhoto3(id,downloadURL,user){

        this.afAuth.authState.subscribe(async user => {
            if(user) {
       
         const {value: file} = await Swal.fire({
          title: 'Select image',
          input: 'file',
          inputAttributes: {
            'accept': 'image/*',
            'aria-label': 'Upload your foreground image'
          }
        })
        
        if (file) {
          const reader = new FileReader
          reader.onload = (e) => {
          
            const id = Math.random().toString(36).substring(2);
                  this.ref = this.afStorage.ref(id);
                  this.task = this.ref.put(file);
                  this.task.snapshotChanges().pipe(
                    finalize(() => {
                      this.downloadURL = this.ref.getDownloadURL()
                      this.downloadURL.subscribe(url =>{
                        (this.image3 = url)
                 
                        // const foodRef = this.afs.doc('users/'+ user.uid);
                        // foodRef.update({
                      
                        //  photoURL: url,
                        
                        // })
                      } );
                    
                    }),
                    
                  ).subscribe() 
                
          }
          
          reader.readAsDataURL(file)
        }
       }
       else {

        const {value: file} = await Swal.fire({
          title: 'Select image',
          input: 'file',
          inputAttributes: {
            'accept': 'image/*',
            'aria-label': 'Upload your foreground image'
          }
        })
        
        if (file) {
          const reader = new FileReader
          reader.onload = (e) => {
          
            const id = Math.random().toString(36).substring(2);
                  this.ref = this.afStorage.ref(id);
                  this.task = this.ref.put(file);
                  this.task.snapshotChanges().pipe(
                    finalize(() => {
                      this.downloadURL = this.ref.getDownloadURL()
                      this.downloadURL.subscribe(url =>{
                        (this.image3 = url)
                 
                        // const foodRef = this.afs.doc('users/'+ user.uid);
                        // foodRef.update({
                      
                        //  photoURL: url,
                        
                        // })
                      } );
                    
                    }),
                    
                  ).subscribe() 
                
          }
          
          reader.readAsDataURL(file)
        }
       }
       })
       
       }
       async editPhotoBackground(id,downloadURLBackground,user){
this.newBackground = true;
        this.afAuth.authState.subscribe(async user => {
            if(user) {
       
         const {value: file} = await Swal.fire({
          title: 'Select background image',
          input: 'file',
          inputAttributes: {
            'accept': 'image/pdf/*',
            'aria-label': 'Upload your background image'
          }
        })
        
        if (file) {
          const reader = new FileReader
          reader.onload = (e) => {
            html2canvas(this.screen.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
              scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight }).then(canvasPDF => {
        
        this.canvasPDF.nativeElement.src = canvasPDF.toDataURL();
        this.downloadLinkPDF.nativeElement.href = canvasPDF.toDataURL('image/png');
        this.downloadLinkPDF.nativeElement.download = 'InstantCoBrand.png';
        this.downloadLinkPDF.nativeElement.click();
          this.newPDF = this.canvasPDF.nativeElement.src = canvasPDF.toDataURL();
              const id = Math.random().toString(36).substring(2);
              this.ref = this.afStorage.ref(id);
              this.task = this.ref.put(this.newPDF);
              this.task.snapshotChanges().pipe(
                finalize(() => {
                  this.downloadURLBackground = this.ref.getDownloadURL()
                  this.downloadURLBackground.subscribe(url =>{
                    (this.backgroundImage = url)
                    this.newpdfSrc = url
                     console.log(url, this.backgroundImage)
                    // const foodRef = this.afs.doc('users/'+ user.uid);
                    // foodRef.update({
                  
                    //  photoURL: url,
                    
                    // })
                  } );
                
                }),
                
              ).subscribe() 
             
            });
         
                
          }
          
          reader.readAsDataURL(file)
        }
       }
       })
       
       }
       dragStarted(event: CdkDragStart,id: any) {
        this.state = 'dragStarted';
        console.log(id)
      }
    
      dragEnded(event: CdkDragEnd,id: any) {
        this.state = 'dragEnded';
    
        if (this.state == 'dragEnded'){
    console.log(this.position)
        }
        
      }
    
    
      dragMoved(event: CdkDragMove,id: any) {
        this.position = `> Position X: ${event.pointerPosition.x} - Y: ${event.pointerPosition.y}`;
        this.xPosition = event.pointerPosition.x
        this.yPosition = event.pointerPosition.y
    console.log(this.xPosition, this.yPosition)
      
      }
      public uploadFile(event:any) {
        let $img: any = document.querySelector('#upload-doc');
        if(event.target.files[0].type == 'application/pdf'){
          if (typeof (FileReader) !== 'undefined') {
            let reader = new FileReader();
            reader.onload = (e: any) => {
              this.pdfSrc = e.target.result;
            };
            this.isPdfUploaded = true;
            reader.readAsArrayBuffer($img.files[0]);
          }
        } else{
          alert('Please upload pdf file')
        }
     }
     afterLoadComplete(pdf: PDFDocumentProxy) {
      this.totalPages = pdf.numPages;
    }
    onPdfLoaded($event){
      html2canvas(this.screen.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight }).then(canvas => {
        
        this.canvas.nativeElement.src = canvas.toDataURL();
        this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        this.downloadLink.nativeElement.download = 'InstantCoBrand.png';
        this.downloadLink.nativeElement.click();
        // const doc = new jsPDF({
        //   orientation: "landscape"});

        // doc.addImage( this.canvas.nativeElement.src,'PNG', 0,0,0,0);
        // doc.save("InstantCoBrand.pdf");
      });
    }
    removeRapid(id){
      this.afs.doc('rapidCards/'+ id).update({
        rapidCard:false
      })
    }
    showRapid(id){
      this.afs.doc('rapidCards/'+ id).update({
        rapidCard:true
      })
    }
    imagePreview(e) {
      const file = (e.target as HTMLInputElement).files[0];
  
      this.myForm.patchValue({
        img: file
      });
  
      this.myForm.get('img').updateValueAndValidity()
  
      const reader = new FileReader();
      reader.onload = () => {
        this.filePath = reader.result as string;
      }
      reader.readAsDataURL(file)
    }
    addVar(){
      const num = 1
      // this.newVar = 
    }
    easterEgg(){
  
      Swal.fire({
        title: 'We knew you would click this',
        icon: 'success',
        html:
          '<iframe width="560" height="315" src="https://www.youtube.com/watch?v=iik25wqIuFo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          '<i class="fa fa-thumbs-up"></i> Nice!',
        confirmButtonAriaLabel: 'Thumbs up, nice!',
        cancelButtonText:
          '<i class="fa fa-thumbs-down"></i>',
        cancelButtonAriaLabel: 'Thumbs down'
      })
    }
}

// gsutil cors set cors.json gs://subpub-app.appspot.com