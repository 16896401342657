import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { B2bComponent } from './b2b/b2b.component';
import { BusinessFoundationComponent } from './business-foundation/business-foundation.component';
import { CompensationComponent } from './compensation/compensation.component';
import { ConsumerFoundationComponent } from './consumer-foundation/consumer-foundation.component';
import { ForBusinessComponent } from './for-business/for-business.component';
import { ForConsumersComponent } from './for-consumers/for-consumers.component';
import { ImgComponent } from './img/img.component';

import { ListComponent } from './list/list.component';
import { TestComponent } from './test/test.component';

import { BrandComponent } from './brand/brand.component';
import { InstantBrandComponent } from './instant-brand/instant-brand.component';
import { CloudComponent } from './cloud/cloud.component';
import { FeedComponent } from './feed/feed.component';
import { NewComponent } from './new/new.component';
import { DiscoveryComponent } from './discovery/discovery.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { BillingComponent } from './billing/billing.component';
import { TwitterComponent } from './twitter/twitter.component';
import { ForgotComponent } from './forgot/forgot.component';

import { LockComponent } from './lock/lock.component';
import { LockedComponent } from './locked/locked.component';
import { ImagelockComponent } from './imagelock/imagelock.component';

const routes: Routes =[
    { path: 'home',             component: HomeComponent },
    { path: 'test',             component: TestComponent },
    { path: 'b2b',             component: B2bComponent },
    { path: 'compensation',             component: CompensationComponent },
    { path: 'business-foundation',             component: BusinessFoundationComponent },
    { path: 'consumer-foundation',             component: ConsumerFoundationComponent },
    { path: 'business',             component: ForBusinessComponent },
    { path: 'consumer',             component: ForConsumersComponent },
    { path: 'B2B',             component: B2bComponent },
    { path: 'Compensation',             component: CompensationComponent },
    { path: 'Business-Foundation',             component: BusinessFoundationComponent },
    { path: 'Consumer-Foundation',             component: ConsumerFoundationComponent },
    { path: 'Business',             component: ForBusinessComponent },
    { path: 'Consumer',             component: ForConsumersComponent },
    { path: 'user-profile',     component: ProfileComponent, canActivate: [AngularFireAuthGuard] },
    { path: 'register',           component: SignupComponent },
    { path: 'forgot',           component: ForgotComponent },
    { path: 'landing',          component: LandingComponent },
    { path: 'img/:id',          component: ImgComponent },
    { path: 'list/:id',          component: ListComponent },
    // { path: 'list/:id',   redirectTo: 'list/:id', pathMatch: 'full' }, // redirect to `YourComponent`
    { path: 'brand',          component: BrandComponent },
    { path: 'Twitter',             component: TwitterComponent },
    { path: 'login',          component: LoginComponent },

    { path: 'instantBrand',       component: InstantBrandComponent},
    { path: 'cloud',       component: CloudComponent},
    { path: 'discovery',       component: DiscoveryComponent},
    { path: 'feed/:id',       component: FeedComponent},
    { path: 'locked/:id',       component: LockedComponent},
    { path: 'lock/:id',       component: LockComponent},
    { path: 'imagelock/:id',       component: ImagelockComponent},
    { path: 'new/:id',       component: NewComponent, canActivate: [AngularFireAuthGuard]},
    { path: 'upgrade/:id',       component: UpgradeComponent, canActivate: [AngularFireAuthGuard]},
    { path: 'billing/:id',       component: BillingComponent, canActivate: [AngularFireAuthGuard]},
    { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
