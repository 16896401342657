import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copywright',
  templateUrl: './copywright.component.html',
  styleUrls: ['./copywright.component.css']
})
export class CopywrightComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
