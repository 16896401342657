import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AuthService } from '../shared/services/auth.service';
import { DataService } from '../shared/services/data.service';

@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.css']
})
export class EnterpriseComponent implements OnInit {

  constructor(public auth:AuthService, public router: Router,public afs: AngularFirestore,private calendar: NgbCalendar, public dataService: DataService, public functions: AngularFireFunctions,) { }

  ngOnInit(): void {
  }
  submitForm(email, name, message){
    console.log(email, name, message)
this.afs.collection('enterprise')
.add({
  name:name,
  email:email,
  message:message
  
}).then();{
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  
  })
  Toast.fire({
    icon: 'success',
    title: 'Message sent!  We will be in touch shortly..'
  })
}
  }
}
