
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';
import { DataService } from '../shared/services/data.service';
import firebase from 'firebase/compat/app';
@Component({
  selector: 'app-discovery',
  templateUrl: './discovery.component.html',
  styleUrls: ['./discovery.component.css']
})
export class DiscoveryComponent implements OnInit {
  id: any;
  imageRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  list: any;
  userData: any;
  UD: unknown;
  profile: unknown;
  latestDoc: any;
  searched: boolean;

  constructor(public auth:AuthService, 
    public router: Router,
    public afs: AngularFirestore,
    private calendar: NgbCalendar, 
    public dataService: DataService, 
    public functions: AngularFireFunctions,
    private afStorage: AngularFireStorage,
    public afAuth: AngularFireAuth,
    private activatedRoute: ActivatedRoute) { 
      this.searched = false
      this.activatedRoute.params.subscribe(paramsId => {
        this.id = paramsId.id;
       
        this.afs.doc('users/'+ this.id).valueChanges().subscribe(data =>{
          this.UD = data;
    
        });
        const db = firebase.firestore()
      this.imageRef = this.afs.collection('images',ref =>ref.where('public','==', true).orderBy('timeStamp', 'desc').limit(30))
      this.list = this.imageRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          this.latestDoc = data.timeStamp
          return { id, ...data };
        }))
      );
      })
    }

  ngOnInit(): void {
  }
  follow(id){

    const increment = firebase.firestore.FieldValue.increment(1);
    const db = firebase.firestore()
    this.afs.doc('users/'+ id ).update({
      followers:firebase.firestore.FieldValue.arrayUnion(this.auth.profile.uid),
      followerCount: increment
    })
    this.afs.doc('users/'+this.auth.profile.uid).update({
     following:firebase.firestore.FieldValue.arrayUnion(id),
     followingCount: increment
    })
    
             }
  unFollow(id){
    
    const increment = firebase.firestore.FieldValue.increment(-1)
    
    this.afs.doc('users/'+ id ).update({
      followers:firebase.firestore.FieldValue.arrayRemove(this.auth.profile.uid),
      followerCount: increment
    })
    this.afs.doc('users/'+this.auth.profile.uid).update({
      following:firebase.firestore.FieldValue.arrayRemove(id),
      followingCount: increment
    })
      }
  go(id){
    this.router.navigate[`#/img/:${id}`]
    this.router.navigateByUrl('/img/'+ id)
  }
  searchField(value){
    this.searched = true
console.log(value)
this.imageRef = this.afs.collection('images',ref =>ref.where('public','==', true).where('tags','array-contains',value).orderBy('timeStamp').limit(30))
this.list = this.imageRef.snapshotChanges().pipe(
  map(actions => actions.map(a => {
    const data = a.payload.doc.data();
    const id = a.payload.doc.id;
    this.latestDoc = data.timeStamp
    return { id, ...data };
  }))
);
  }
  loadMore(value){
     
    this.imageRef = this.afs.collection('images',ref =>ref.where('public','==', true).where('tags','array-contains',value).orderBy('timeStamp', 'desc').limit(30).startAfter(this.latestDoc || 1000000000000000000000));
    this.list = this.imageRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        this.latestDoc = data.timeStamp
        return { id, ...data };
      }))
    );
  }
  loadMoreNoSearch(){
     
    this.imageRef = this.afs.collection('images',ref =>ref.where('public','==', true).orderBy('timeStamp', 'desc').limit(30).startAfter(this.latestDoc || 0));
    this.list = this.imageRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        this.latestDoc = data.timeStamp
        return { id, ...data };
      }))
    );
  }
}
