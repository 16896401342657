import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';


@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
}
