import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';
import { DataService } from '../shared/services/data.service';
import firebase from 'firebase/compat/app';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  id: any;
  imageRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  list: any;
  userData: any;
  UD: any;

  constructor(public auth:AuthService, 
    public router: Router,
    public afs: AngularFirestore,
    private calendar: NgbCalendar, 
    public dataService: DataService, 
    public functions: AngularFireFunctions,
    private afStorage: AngularFireStorage,
    public afAuth: AngularFireAuth,
    private activatedRoute: ActivatedRoute) { 
      this.activatedRoute.params.subscribe(paramsId => {
        this.id = paramsId.id;
        console.log(this.id);
        const db = firebase.firestore()
        db.collection("users").where("displayName", "==", this.id)
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                this.afs.collection('users/').doc(doc.data().uid).valueChanges().subscribe(data =>{
                  this.UD = data;
            
                });
              this.imageRef = this.afs.collection('images',ref =>ref.where('uid','==', doc.data().uid).where('public','==', true))
              this.list = this.imageRef.snapshotChanges().pipe(
                map(actions => actions.map(a => {
                  const data = a.payload.doc.data();
                  const id = a.payload.doc.id;
                 
                  return { id, ...data };
                }))
              );
            });
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });
     
      })
    }

  ngOnInit(): void {
  }
  follow(id){

    const increment = firebase.firestore.FieldValue.increment(1);
    const db = firebase.firestore()
    try{
      this.afs.doc('users/'+ id ).update({
        followers:firebase.firestore.FieldValue.arrayUnion(this.auth.profile.uid),
        followerCount: increment
      })
      this.afs.doc('users/'+this.auth.profile.uid).update({
       following:firebase.firestore.FieldValue.arrayUnion(id),
       followingCount: increment
      })
    }
    catch (error) {
      console.error(error);
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 5000,
        
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'error',
        title: 'Please log in or register to follow someone...'
      })

    }
  
    
             }
  unFollow(id){
    
    const increment = firebase.firestore.FieldValue.increment(-1)
    
    this.afs.doc('users/'+ id ).update({
      followers:firebase.firestore.FieldValue.arrayRemove(this.auth.profile.uid),
      followerCount: increment
    })
    this.afs.doc('users/'+this.auth.profile.uid).update({
      following:firebase.firestore.FieldValue.arrayRemove(id),
      followingCount: increment
    })
      }
  go(id){
    this.router.navigate[`#/img/:${id}`]
    this.router.navigateByUrl('/img/'+ id)
  }
}
