import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';
import { DataService } from '../shared/services/data.service';

@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.css']
})
export class FeedComponent implements OnInit {
  imageRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  list: any;

  constructor(public auth:AuthService, 
    public router: Router,
    public afs: AngularFirestore,
    private calendar: NgbCalendar, 
    public dataService: DataService, 
    public functions: AngularFireFunctions,
    private afStorage: AngularFireStorage,
    public afAuth: AngularFireAuth,
    private activatedRoute: ActivatedRoute) {
    this.imageRef = this.afs.collection('images',ref =>ref.where('uid','==', this.auth.profile.uid).where('public','==', true))
    this.list = this.imageRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
       
        return { id, ...data };
      }))
    );
    }


  ngOnInit(): void {
  }

}
