import { Component, OnInit } from '@angular/core';
import {load} from 'google-fonts-loader';

load([
    {family: 'Roboto', wght: 400},
    {family: 'Roboto', wght: 400, ital: 1},
]);

@Component({
  selector: 'app-twitter',
  templateUrl: './twitter.component.html',
  styleUrls: ['./twitter.component.css']
})
export class TwitterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
