import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-for-consumers',
  templateUrl: './for-consumers.component.html',
  styleUrls: ['./for-consumers.component.css']
})
export class ForConsumersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
