

    import {Component, OnInit} from '@angular/core';
    import {Cloudinary, CloudinaryImage} from '@cloudinary/url-gen';
    import {Transformation} from '@cloudinary/url-gen';
    
    // Import required actions.
    import {thumbnail, scale} from '@cloudinary/url-gen/actions/resize';
    import {byRadius} from '@cloudinary/url-gen/actions/roundCorners';
    import {sepia} from '@cloudinary/url-gen/actions/effect';
    import {source} from '@cloudinary/url-gen/actions/overlay';
    import {opacity,brightness} from '@cloudinary/url-gen/actions/adjust';
    import {byAngle} from '@cloudinary/url-gen/actions/rotate'
    
    // Import required qualifiers.
    import {image, text} from '@cloudinary/url-gen/qualifiers/source';
    import {Position} from '@cloudinary/url-gen/qualifiers/position';
    import {compass} from '@cloudinary/url-gen/qualifiers/gravity';
    import {focusOn} from "@cloudinary/url-gen/qualifiers/gravity";
    import {FocusOn} from "@cloudinary/url-gen/qualifiers/focusOn";
import { TextStyle } from '@cloudinary/url-gen/qualifiers/textStyle';
    
    @Component({
      selector: 'app-cloud',
      templateUrl: './cloud.component.html',
      styleUrls: ['./cloud.component.css']
    })
    
    export class CloudComponent implements OnInit {
      img: CloudinaryImage
    
      ngOnInit() {
    
        // Create and configure your Cloudinary instance.
        const cld = new Cloudinary({
          cloud: {
            cloudName: 'demo'
          }
        }); 
    
        // Use the image with public ID, 'front_face'.
        this.img = cld.image('front_face');
    
        // Apply the transformation.
        this.img
        .resize(thumbnail().width(1000).height(1000).gravity(focusOn(FocusOn.face())))  // Crop the image.
        .roundCorners(byRadius(150))    // Round the corners.
        .effect(sepia())  // Apply a sepia effect.
        .overlay(   // Overlay the Cloudinary logo.
          source(
            
            image('cloudinary_icon_blue')
              .transformation(new Transformation()
              .resize(scale(50)) // Resize the logo.
                .adjust(opacity(10))  // Adjust the opacity of the logo.
              .adjust(brightness(200)))
                // Adjust the brightness of the logo.       
          )
          .position(new Position().gravity(compass('south_east')).offsetX(5).offsetY(5))   // Position the logo.
        )
        .rotate(byAngle(0))  // Rotate the result.
        .format('png')
        .overlay(
          source(
            image("black_bar").transformation(
              new Transformation()
                .resize(scale().width(0.5).height(0.3).relative())
                .adjust(opacity(60))
            )
          )
          .position(new Position().gravity(compass('south_east')).offsetX(5).offsetY(5)) 
        )
        .overlay(
          source(
            text(
              "Cloudinary",
              new TextStyle("Arial", 60).fontWeight("bold")
            ).textColor("white")
           
          )
         // Deliver as PNG. */
         .position(new Position().gravity(compass('south_east')).offsetX(15).offsetY(15)) 
        )}
    }