


  import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
  import { AngularFireAuth } from '@angular/fire/compat/auth';
  import { AngularFirestore } from '@angular/fire/compat/firestore';
  import { AngularFireFunctions } from '@angular/fire/compat/functions';
  import { AngularFireStorage } from '@angular/fire/compat/storage';
  import { ActivatedRoute, Router } from '@angular/router';
  import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
  import { finalize, map } from 'rxjs/operators';
  import Swal from 'sweetalert2';
  import { AuthService } from '../shared/services/auth.service';
  import { DataService } from '../shared/services/data.service';
  import { CdkDragEnd, CdkDragStart, CdkDragMove, CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragEnter, CdkDragExit } from '@angular/cdk/drag-drop';
  import {DragDropModule} from '@angular/cdk/drag-drop';
  import html2canvas from 'html2canvas';
  import { jsPDF } from "jspdf";
  import firebase from 'firebase/compat/app';

  // import {firebase} from 'firebase/app'
  
  @Component({
    selector: 'app-locked',
    templateUrl: './locked.component.html',
    styleUrls: ['./locked.component.css']
  })
  export class LockedComponent implements OnInit {
    items : any;
  
    basket: any;
    title: string;
    newItems: string[];
    image2: any;
    image3: any;
    text1Color: string;
    text1Weight: number;
    text1Size: number;
    text1: string;
    text2Color: string;
    text2Weight: number;
    text2Size: number;
    text2: string;
    text3Color: string;
    text3Weight: number;
    text3Size: number;
    text3: string;
    backgroundWidth1: number;
    backgroundHeight1: number;
    cornerRadius1: number;
    backgroundColor1: string;
    backgroundWidth2: number;
    backgroundHeight2: number;
    cornerRadius2: number;
    backgroundColor2: string;
    backgroundWidth3: number;
    backgroundHeight3: number;
    cornerRadius3: number;
    backgroundColor3: string;
    background3Z: number;
    background2Z: number;
    background1Z: number;
    avatar1Z: number;
    avatar2Z: number;
    avatar3Z: number;
    text1Z: number;
    text2Z: number;
    text3Z: number;
    id: any;
    imageRef: any;
    bigImage: any;
    userData: any;
    rapidRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
    rapidCards: any;
    rapidCardSize: number;
    rapidCardZ: number;
    currentItem: any;
    following: boolean;
    UD: any;
  
    drop(event: CdkDragDrop<string[]>) {
      if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      } else {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
      }
    }
    pdfSrc = '';
    @ViewChild('screen', { static: true }) screen: ElementRef;
    // @ViewChild('screen') screen: ElementRef;
    @ViewChild('canvas') canvas: ElementRef;
    @ViewChild('downloadLink') downloadLink: ElementRef;
    @ViewChild('screen2', { static: true }) screen2: ElementRef;
    // @ViewChild('screen') screen: ElementRef;
    @ViewChild('canvas2') canvas2: ElementRef;
    @ViewChild('downloadLink2') downloadLink2: ElementRef;
      model = {
          left: true,
          middle: false,
          right: false
      };
  
      focus;
      focus1;
      ref: import("@angular/fire/compat/storage").AngularFireStorageReference;
      task: any;
      downloadURL: any;
      image: any;
      backgroundImage: any;
      titleColor: string;
      titleWeight: number;
      titleSize: number;
      bodyColor: string;
      bodyWeight: number;
      bodySize: number;
      
      state: string;
      xPosition: number;
      yPosition: number;
      position = '';
      downloadURLBackground: any;
    file: string;
    backgroundWidth: any;
    backgroundHeight: any;
    backgroundColor: any;
    cornerRadius: any;
    avatarCorner1:any;
    avatarCorner2:any;
    avatarCorner3:any;
    avatarSize: number;
    avatarSize2: number;
    avatarSize3: number;
    arrayOne: any;
    arrayTwo: any;
    backgroundTransparancy1: any;
    backgroundRotate1: any;
    backgroundTransparancy2: any;
    backgroundRotate2: any;
    backgroundTransparancy3: any;
    backgroundRotate3: any;
    letters = '';
           color = '#';
      constructor(public auth:AuthService, 
          public router: Router,
          public afs: AngularFirestore,
          private calendar: NgbCalendar, 
          public dataService: DataService, 
          public functions: AngularFireFunctions,
          private afStorage: AngularFireStorage,
          public afAuth: AngularFireAuth,
          private activatedRoute: ActivatedRoute
   ) {
    this.activatedRoute.params.subscribe(paramsId => {
      this.id = paramsId.id;

      
      const increment = firebase.firestore.FieldValue.increment(1);
      this.afs.doc('images/'+ this.id).update({
        views: increment,

      })
   
   
      this.afAuth.authState.subscribe(async user => {
        if(user) {
      
      this.rapidRef = this.afs.collection('rapidCards',ref =>ref.where('uid','==', user.uid))
      this.rapidCards = this.rapidRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
         
          return { id, ...data };
        }))
      );
      this.rapidRef = this.afs.collection('rapidCards',ref =>ref.where('uid','==', user.uid))
      this.rapidCards = this.rapidRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
         
          return { id, ...data };
        }))
      );
        }})
  });
  const db = firebase.firestore();
  const increment = firebase.firestore.FieldValue.increment(1);
  const docRef = db.doc('images/'+ this.id);

  docRef.get().then((doc) => {
      if (doc.exists) {
         
          this.bigImage = doc.data();
         
          this.afs.doc('users/'+ this.bigImage.uid).valueChanges().subscribe(d =>{
            this.UD = d;
            this.currentItem = this.bigImage.photoURL
            console.log(this.UD)
          });
          this.afs.doc('users/'+ this.bigImage.uid).update({
            views:increment
          })
      } else {
          // doc.data() will be undefined in this case
         
      }
  }).catch((error) => {
    
  });
           }
           
           
           ngOnInit() {
         
            
          
            // this.afs.doc('images/'+ this.id).valueChanges().subscribe(data =>{
            //   this.bigImage = data;
            //   this.afs.doc('users/'+ this.bigImage.uid).valueChanges().subscribe(d =>{
            //     this.UD = d;
            //     this.currentItem = this.bigImage.photoURL
            //   });
            //   this.afs.doc('users/'+ this.bigImage.uid).update({
            //     views:increment
            //   })
            // });
            this.file = '';
           
    
          }
          follow(id){

            const increment = firebase.firestore.FieldValue.increment(1);
            const db = firebase.firestore()
            try{
              this.afs.doc('users/'+ id ).update({
                followers:firebase.firestore.FieldValue.arrayUnion(this.auth.profile.uid),
                followerCount: increment
              })
              this.afs.doc('users/'+this.auth.profile.uid).update({
               following:firebase.firestore.FieldValue.arrayUnion(id),
               followingCount: increment
              })
            }
            catch (error) {
              console.error(error);
              const Toast = Swal.mixin({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 5000,
                
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              })
              
              Toast.fire({
                icon: 'error',
                title: 'Please log in or register to follow someone...'
              })
        
            }
          }

          unFollow(id){
            
            const increment = firebase.firestore.FieldValue.increment(-1)
            
            this.afs.doc('users/'+ id ).update({
              followers:firebase.firestore.FieldValue.arrayRemove(this.auth.profile.uid),
              followerCount: increment
            })
            this.afs.doc('users/'+this.auth.profile.uid).update({
              following:firebase.firestore.FieldValue.arrayRemove(id),
              followingCount: increment
            })
              }
          goToPage(id){
            this.router.navigateByUrl('/list/'+ id)
          }
       uploadFile(file){

       }
        captureScreen()
        {
          const increment = firebase.firestore.FieldValue.increment(1);
          this.afs.doc('images/'+ this.id).update({
            coBrands: increment,
            
          })
          const increment2 = firebase.firestore.FieldValue.increment(1);
          this.afs.doc('stats/users').update({
            coBrands: increment2,
            
          })
          html2canvas(this.screen.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            windowWidth: document.documentElement.offsetWidth,
            windowHeight: document.documentElement.offsetHeight }).then(canvas => {
            
            this.canvas.nativeElement.src = canvas.toDataURL();
            this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
            this.downloadLink.nativeElement.download = 'InstantCoBrand.png';
            this.downloadLink.nativeElement.click();
            
            // const doc = new jsPDF({
            //   orientation: "landscape"});
    
            // doc.addImage( this.canvas.nativeElement.src,'PNG', 0,0,0,0);
            // doc.save("InstantCoBrand.pdf");
          });
         
        }
        captureScreen2()
        {
          html2canvas(this.screen2.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            windowWidth: document.documentElement.offsetWidth,
            windowHeight: document.documentElement.offsetHeight }).then(canvas2 => {
            
            this.canvas2.nativeElement.src = canvas2.toDataURL();
            this.downloadLink2.nativeElement.href = canvas2.toDataURL('image/png');
            this.downloadLink2.nativeElement.download2 = 'InstantCoBrand.png';
            this.downloadLink2.nativeElement.click();
            // const doc = new jsPDF({
            //   orientation: "landscape"});
    
            // doc.addImage( this.canvas.nativeElement.src,'PNG', 0,0,0,0);
            // doc.save("InstantCoBrand.pdf");
          });
         
        }
        capturePDF()
        {
          html2canvas(this.screen.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            windowWidth: document.documentElement.offsetWidth,
            windowHeight: document.documentElement.offsetHeight }).then(canvas => {
            
            this.canvas.nativeElement.src = canvas.toDataURL();
            // this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
            // this.downloadLink.nativeElement.download = 'InstantCoBrand.png';
            // this.downloadLink.nativeElement.click();
            const pdf = new jsPDF({
              orientation: 'landscape',
            });
            const imgProps= pdf.getImageProperties(this.canvas.nativeElement.src);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            const marginTop = pdfHeight / 2;
            pdf.addImage(this.canvas.nativeElement.src, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('InstantCoBrand.pdf');
    
           
          });
         
        }
        capturePDFPortrait()
        {
          html2canvas(this.screen.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            windowWidth: document.documentElement.offsetWidth,
            windowHeight: document.documentElement.offsetHeight }).then(canvas => {
            
            this.canvas.nativeElement.src = canvas.toDataURL();
            // this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
            // this.downloadLink.nativeElement.download = 'InstantCoBrand.png';
            // this.downloadLink.nativeElement.click();
            const pdf = new jsPDF({
              orientation: 'portrait',
            });
            const imgProps= pdf.getImageProperties(this.canvas.nativeElement.src);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            const marginTop = pdfHeight / 2
            pdf.addImage(this.canvas.nativeElement.src, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('InstantCoBrand.pdf');
    
           
          });
         
        }
        upload(pdf){
          this.afAuth.authState.subscribe(async user => {
            if(user) {
        if (pdf){
            const reader = new FileReader
            reader.onload = (e) => {
            
              const id = Math.random().toString(36).substring(2);
                    this.ref = this.afStorage.ref(id);
                    this.task = this.ref.put(pdf);
                    this.task.snapshotChanges().pipe(
                      finalize(() => {
                        this.downloadURL = this.ref.getDownloadURL()
                        this.downloadURL.subscribe(url =>{
                          ( this.pdfSrc = url, this.file = url)
                         
                          // const foodRef = this.afs.doc('users/'+ user.uid);
                          // foodRef.update({
                        
                          //  photoURL: url,
                          
                          // })
                        } );
                      
                      }),
                      
                    ).subscribe() 
                  
            }
            
            reader.readAsDataURL(pdf)
          }
        }
          })
        }
          async editPhoto(id,downloadURL,user){
    
            this.afAuth.authState.subscribe(async user => {
                if(user) {
           
             const {value: file} = await Swal.fire({
              title: 'Select image',
              input: 'file',
              inputAttributes: {
                'accept': 'image/*',
                'aria-label': 'Upload your foreground image'
              }
            })
            
            if (file) {
              const reader = new FileReader
              reader.onload = (e) => {
              
                const id = Math.random().toString(36).substring(2);
                      this.ref = this.afStorage.ref(id);
                      this.task = this.ref.put(file);
                      this.task.snapshotChanges().pipe(
                        finalize(() => {
                          this.downloadURL = this.ref.getDownloadURL()
                          this.downloadURL.subscribe(url =>{
                            (this.image = url)
                     
                            // const foodRef = this.afs.doc('users/'+ user.uid);
                            // foodRef.update({
                          
                            //  photoURL: url,
                            
                            // })
                          } );
                        
                        }),
                        
                      ).subscribe() 
                    
              }
              
              reader.readAsDataURL(file)
            }
           }
           else {
    
            const {value: file} = await Swal.fire({
              title: 'Select image',
              input: 'file',
              inputAttributes: {
                'accept': 'image/*',
                'aria-label': 'Upload your foreground image'
              }
            })
            
            if (file) {
              const reader = new FileReader
              reader.onload = (e) => {
              
                const id = Math.random().toString(36).substring(2);
                      this.ref = this.afStorage.ref(id);
                      this.task = this.ref.put(file);
                      this.task.snapshotChanges().pipe(
                        finalize(() => {
                          this.downloadURL = this.ref.getDownloadURL()
                          this.downloadURL.subscribe(url =>{
                            (this.image = url)
                     
                            // const foodRef = this.afs.doc('users/'+ user.uid);
                            // foodRef.update({
                          
                            //  photoURL: url,
                            
                            // })
                          } );
                        
                        }),
                        
                      ).subscribe() 
                    
              }
              
              reader.readAsDataURL(file)
            }
           }
           })
           
           }
           async editPhoto2(id,downloadURL,user){
    
            this.afAuth.authState.subscribe(async user => {
                if(user) {
           
             const {value: file} = await Swal.fire({
              title: 'Select image',
              input: 'file',
              inputAttributes: {
                'accept': 'image/*',
                'aria-label': 'Upload your foreground image'
              }
            })
            
            if (file) {
              const reader = new FileReader
              reader.onload = (e) => {
              
                const id = Math.random().toString(36).substring(2);
                      this.ref = this.afStorage.ref(id);
                      this.task = this.ref.put(file);
                      this.task.snapshotChanges().pipe(
                        finalize(() => {
                          this.downloadURL = this.ref.getDownloadURL()
                          this.downloadURL.subscribe(url =>{
                            (this.image2 = url)
                     
                            // const foodRef = this.afs.doc('users/'+ user.uid);
                            // foodRef.update({
                          
                            //  photoURL: url,
                            
                            // })
                          } );
                        
                        }),
                        
                      ).subscribe() 
                    
              }
              
              reader.readAsDataURL(file)
            }
           }
           else {
    
            const {value: file} = await Swal.fire({
              title: 'Select image',
              input: 'file',
              inputAttributes: {
                'accept': 'image/*',
                'aria-label': 'Upload your foreground image'
              }
            })
            
            if (file) {
              const reader = new FileReader
              reader.onload = (e) => {
              
                const id = Math.random().toString(36).substring(2);
                      this.ref = this.afStorage.ref(id);
                      this.task = this.ref.put(file);
                      this.task.snapshotChanges().pipe(
                        finalize(() => {
                          this.downloadURL = this.ref.getDownloadURL()
                          this.downloadURL.subscribe(url =>{
                            (this.image2 = url)
                     
                            // const foodRef = this.afs.doc('users/'+ user.uid);
                            // foodRef.update({
                          
                            //  photoURL: url,
                            
                            // })
                          } );
                        
                        }),
                        
                      ).subscribe() 
                    
              }
              
              reader.readAsDataURL(file)
            }
           }
           })
           
           }
           async editPhoto3(id,downloadURL,user){
    
            this.afAuth.authState.subscribe(async user => {
                if(user) {
           
             const {value: file} = await Swal.fire({
              title: 'Select image',
              input: 'file',
              inputAttributes: {
                'accept': 'image/*',
                'aria-label': 'Upload your foreground image'
              }
            })
            
            if (file) {
              const reader = new FileReader
              reader.onload = (e) => {
              
                const id = Math.random().toString(36).substring(2);
                      this.ref = this.afStorage.ref(id);
                      this.task = this.ref.put(file);
                      this.task.snapshotChanges().pipe(
                        finalize(() => {
                          this.downloadURL = this.ref.getDownloadURL()
                          this.downloadURL.subscribe(url =>{
                            (this.image3 = url)
                     
                            // const foodRef = this.afs.doc('users/'+ user.uid);
                            // foodRef.update({
                          
                            //  photoURL: url,
                            
                            // })
                          } );
                        
                        }),
                        
                      ).subscribe() 
                    
              }
              
              reader.readAsDataURL(file)
            }
           }
           else {
    
            const {value: file} = await Swal.fire({
              title: 'Select image',
              input: 'file',
              inputAttributes: {
                'accept': 'image/*',
                'aria-label': 'Upload your foreground image'
              }
            })
            
            if (file) {
              const reader = new FileReader
              reader.onload = (e) => {
              
                const id = Math.random().toString(36).substring(2);
                      this.ref = this.afStorage.ref(id);
                      this.task = this.ref.put(file);
                      this.task.snapshotChanges().pipe(
                        finalize(() => {
                          this.downloadURL = this.ref.getDownloadURL()
                          this.downloadURL.subscribe(url =>{
                            (this.image3 = url)
                     
                            // const foodRef = this.afs.doc('users/'+ user.uid);
                            // foodRef.update({
                          
                            //  photoURL: url,
                            
                            // })
                          } );
                        
                        }),
                        
                      ).subscribe() 
                    
              }
              
              reader.readAsDataURL(file)
            }
           }
           })
           
           }
           async editPhotoBackground(id,downloadURLBackground,user){
    
            this.afAuth.authState.subscribe(async user => {
                if(user) {
           
             const {value: file} = await Swal.fire({
              title: 'Select background image',
              input: 'file',
              inputAttributes: {
                'accept': 'image/pdf/*',
                'aria-label': 'Upload your background image'
              }
            })
            
            if (file) {
              const reader = new FileReader
              reader.onload = (e) => {
             
                const id = Math.random().toString(36).substring(2);
                      this.ref = this.afStorage.ref(id);
                      this.task = this.ref.put(file);
                      this.task.snapshotChanges().pipe(
                        finalize(() => {
                          this.downloadURLBackground = this.ref.getDownloadURL()
                          this.downloadURLBackground.subscribe(url =>{
                            (this.backgroundImage = url)
                            this.pdfSrc = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf'
                  
                            // const foodRef = this.afs.doc('users/'+ user.uid);
                            // foodRef.update({
                          
                            //  photoURL: url,
                            
                            // })
                          } );
                        
                        }),
                        
                      ).subscribe() 
                    
              }
              
              reader.readAsDataURL(file)
            }
           }
           })
           
           }
           dragStarted(event: CdkDragStart,id: any) {
            this.state = 'dragStarted';

          }
        
          dragEnded(event: CdkDragEnd,id: any) {
            this.state = 'dragEnded';
        
            if (this.state == 'dragEnded'){

            }
            
          }
        
        
          dragMoved(event: CdkDragMove,id: any) {
            this.position = `> Position X: ${event.pointerPosition.x} - Y: ${event.pointerPosition.y}`;
            this.xPosition = event.pointerPosition.x
            this.yPosition = event.pointerPosition.y
 
          
          }
          removeRapid(id){
            this.afs.doc('rapidCards/'+ id).update({
              rapidCard:false
            })
          }
          showRapid(id){
            this.afs.doc('rapidCards/'+ id).update({
              rapidCard:true
            })
          }
    }
    
    // gsutil cors set cors.json gs://subpub-app.appspot.com