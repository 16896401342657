import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

declare let FontFace: any;
@Injectable({
  providedIn: 'root'
})
export class FontsService {
  fonts: any;
  link: any;

  constructor(private httpClient: HttpClient,) { 

//    this.getDataFromServer().then(resp => {
              
//     this.fonts = resp;
// console.log(this.fonts);
// this.fonts.items.forEach((element) => {
 

//   let customFont = new FontFace(element.family, 'url('+element.files.regular +')');
//   customFont.load().then((res) => {
//     document['fonts'].add(res);
//   }).catch((error) => {
//     console.log(error)
//   })
// });
 
//   });
//   }
//   getDataFromServer() {
//     const params = {
//       'key': 'AIzaSyA8bgCLOM2E5uq2zAHKbcs0hICjwODLQrg'
//       // param2: value2
//     }
//     const url = 'https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity'

//     // { params: params } is the same as { params } 
//     // look for es6 object literal to read more
//     return this.httpClient.get(url, { params }).toPromise(
    
//     );
//   }
  }
}