
import { CdkDragDrop, CdkDragEnd, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { finalize, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthService } from '../shared/services/auth.service';
import { DataService } from '../shared/services/data.service';
import firebase from 'firebase/compat/app';
import { Console } from 'console';
import { FontsService } from '../services/fonts.service';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class NewComponent implements OnInit {
    
  @Input() image = '';

  @ViewChild('screen', { static: false }) screen: ElementRef;
  // @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  newZ: any;
  addZ: number;
  // @HostListener('window:beforeunload', ['$event'])
  // showAlertMessageWhenClosingTab($event) {
  //     $event.returnValue = 'Your data will be lost!';
  // }
  imgs: [];
  empForm: FormGroup;
  filePath: string;
  myForm: FormGroup;
  imageItem: string;
  imageWidth0:any;
  newVar: any;
  arr: any[];
  layerArr: any[];
  layer: any[];
  textValue: string;
  reversedList: Array<any> = [];
  num: number;
  up: number;
  down: number;
  backgroundImageWidth: any;
  templateWidth: any;
  templateHeight: any;
  cropOpen: boolean;
  saving: boolean;
  height: number;
  width: number;
  detected: boolean;
  font = new FormControl();
  fonts : string[] = [
    'Roboto','Roboto Mono','Sarina','Work_Sans','Mrs_Sheppards','Hurricane','Dancing','Inter','Koulen','Open Sans','Padauk','Harmattan','Montserrat','Lato','Oswald','Merriweather','Anton','Lobster','Pacifico','Alfa Slab One'
  ]
  rapidRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  rapidCards: any;
  instant: boolean;
  ref: import("@angular/fire/compat/storage").AngularFireStorageReference;
  task: any;
  downloadURLBackground: any;
  backgroundImage: any;
  pdfSrc: any;
  library: boolean;
  imageRef: import("@angular/fire/compat/firestore").AngularFirestoreCollection<any>;
  images: any;
  id: any;
  meta: Promise<void>;
  locked: boolean;
  light: boolean;
  dark: boolean;
  title: boolean;
  logo: boolean;
  contact: boolean;
  imageBlock: boolean;
  contentBlock: string;
  notSaved: boolean;
  blockSize: number;
  dragPosition: { readonly x: number; readonly y: number; };
  newContactWidth: number;
  newContactHeight: number;
  textColor: string;
  fontColor: string;



 



  constructor(private fb: FormBuilder,  public functions: AngularFireFunctions,
    private afStorage: AngularFireStorage,
    public afAuth: AngularFireAuth, public afs: AngularFirestore,public auth:AuthService, 
    public router: Router,
    public dataService: DataService,
    private activatedRoute: ActivatedRoute, private fontService: FontsService) {
      this.title = false;
      this.logo = false;
      this.contact = false;
      this.imageBlock = false
      this.contentBlock ='Example Title<br>License #12345<br>(555) 555-5555<br>email@fakeemail.com'
      this.light = false;
      this.dark = true;
      this.notSaved = false;
      this.blockSize = 40
      this.locked = false;
      this.textColor = 'dark'
      this.activatedRoute.params.subscribe(paramsId => {
        this.id = paramsId.id;
      })
      const db = firebase.firestore()
      console.log(this.image)
    this.saving = false;
    this.textValue='';
    this.myForm = this.fb.group({
      img: [null],
      filename: ['']
    })
    this.arr = [];
    this.layerArr = [];
    this.layer = [];
    this.reversedList = this.layer.slice().reverse();
    this.num = 0
  this.templateWidth = 60
  this.templateHeight = 53
  this.cropOpen = true
  this.instant = true
  this.library = true
  this.detected = false
  this.afAuth.authState.subscribe(async user => {
    if(user) {
  
  this.rapidRef = this.afs.collection('rapidCards',ref =>ref.where('uid','==', user.uid))
  this.rapidCards = this.rapidRef.snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data();
      const id = a.payload.doc.id;
     
      return { id, ...data };
    }))
  );
  this.imageRef = this.afs.collection('images',ref =>ref.where('uid','==', user.uid))
  this.images = this.imageRef.snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data();
      const id = a.payload.doc.id;
      return { id, ...data };
    }))
  );
    }})
  }
  ngOnInit() {
   
   
  }
  ngAfterViewInit(){
    setTimeout(() => {
      this.height = (<HTMLElement>this.screen.nativeElement).getBoundingClientRect().height;
      this.width = (<HTMLElement>this.screen.nativeElement).getBoundingClientRect().width;
      this.detected = true
  });
  
 
 
  }
  dragContact($event: CdkDragEnd) {
    console.log($event.source.getFreeDragPosition());
    this.dragPosition = $event.source.getFreeDragPosition()
   
    this.height = (<HTMLElement>this.screen.nativeElement).getBoundingClientRect().height;
      this.width = (<HTMLElement>this.screen.nativeElement).getBoundingClientRect().width;
      this.newContactWidth = (this.dragPosition.x /  this.width) *100
      this.newContactHeight = (this.dragPosition.y /  this.height) *100
  //  this.afs.collection('images').doc(this.id).update({
  //   contactWidth:this.newContactWidth,
  //   contactHeight:this.newContactHeight
  //  })
    // console.log(this.newWidth, this.newHeight)
}

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.arr, event.previousIndex, event.currentIndex);
    moveItemInArray(this.layer, event.previousIndex, event.currentIndex);
  }
  captureScreen(){
    console.log(this.locked, this.newContactWidth,this.newContactHeight, this.textColor, this)
    if(this.auth.profile.status != 'active'){
      this.notActive()
      html2canvas(this.screen.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight }).then(canvas => {
        
        this.canvas.nativeElement.src = canvas.toDataURL();
        this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        this.downloadLink.nativeElement.download = 'InstantCoBrand.png';
        this.downloadLink.nativeElement.click();
        })
    }
    else {

  this.saving = true
    html2canvas(this.screen.nativeElement, { useCORS: true,allowTaint: true,scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight }).then(canvas => {
      
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'InstantCoBrand.png';
      this.downloadLink.nativeElement.click();
      // const doc = new jsPDF({
        this.saving = false;
      //   orientation: "landscape"});

      // doc.addImage( this.canvas.nativeElement.src,'PNG', 0,0,0,0);
      // doc.save("InstantCoBrand.pdf");
      const base = this.canvas.nativeElement.src
      this.afAuth.authState.subscribe(async user => {
        if(user) {
          var metadata = {
            contentType: 'image/jpeg',
          };
          var base64data = base.replace("data:image/png;base64,", "");
      var bs = atob(base64data);
      var buffer = new ArrayBuffer(bs.length);
      var ba = new Uint8Array(buffer);
      for (var i = 0; i < bs.length; i++) {
          ba[i] = bs.charCodeAt(i);
      }
      const file = new Blob([ba], { type: "image/png" });
          const id = Math.random().toString(36).substring(2);
                this.ref = this.afStorage.ref(id);
                this.task = this.ref.put(file);
                this.task.snapshotChanges().pipe(
                  finalize(() => {
                    
                    this.downloadURLBackground = this.ref.getDownloadURL()
                    this.meta =this.ref.getMetadata().toPromise().then((metadata) => {
                      const increment = firebase.firestore.FieldValue.increment(metadata.size);
                      // const double = firebase.firestore.FieldValue.increment(metadata.size * 2);
                        this.downloadURLBackground.subscribe(url =>{
                          (this.backgroundImage = url)
                          this.pdfSrc = url
                          this.afs.collection('users/').doc(user.uid).update({
                            storage:increment
                          })
                      // const foodRef = this.afs.collection('savedImages/');
                      // foodRef.add({
                    
                      //  savedImageURL: url,
                      //  size:increment,
                      //  uid: user.uid,
                      //  timeStamp: Date.now(),
                      // displayName: this.auth.profile.displayName
                      // })
                      // console.log(this.locked, this.newContactWidth,this.newContactHeight, this.textColor)
                       this.afs.collection('images/').add({
                          photoURL: url,
                          uid: user.uid,
                          timeStamp: Date.now(),
                          profileImg: this.auth.profile.photoURL || '',
                          displayName:this.auth.profile.displayName || '',
                          size:increment,
                          contactHeight:this.newContactHeight,
                          contactWidth:this.newContactWidth,
                          textColor:this.textColor,
                          locked:this.locked
                     
                        })
                        const Toast = Swal.mixin({
                          toast: true,
                          position: 'top',
                          showConfirmButton: false,
                          timer: 3000,
                          
                          didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                          }
                        })
                        
                        Toast.fire({
                          icon: 'success',
                          title: 'Saved!'
                        })
                      
                    
                    } );
                    })
                  }),
                  
                ).subscribe() 
                }})
    });
  }
  }

  imagePreview(e,i) {
    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    const img = new Image();


    reader.onload = () => {
      this.arr[i].image = reader.result as string;
    
   
    // document.getElementById('result').appendChild(img);
    }
    reader.readAsDataURL(file)
  }

  notActive(){
    Swal.fire({
      title: 'Note!',
      text: 'You need to have an ACTIVE billing account to store and create Co-brandable images. Downloading image to your device now.  Sign up here!',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, I want to upgrade for $10 .mo!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/upgrade/'+ this.auth.profile.uid)
      } 
   })
  }
 

  newLayer(value) {

    this.num++

    const layer = {
      type: value,
      textValue: '',
      id: this.num,
      Z: this.num,
      imageSize: 50,
      image: '',
      imageRotate: 0,
      imageRadius: 0,
      imageOpacity: 100,
      shapeWidth: 50,
      shapeHeight: 50,
      shapeColor: '#000',
      shapeRadius: 0,
      shapeOpacity: 100,
      shapeRotate: 0,
      textSize: 3,
      textColor:'light',
      textOpacity: 100,
      textRotate: 0,
      textBold: 300,
      textAlignment: 'center',
      show: true,
      collapse: false,
      font:'Work_Sans'
     

    }
    this.layer.splice(0, 0,layer);
    this.arr.splice(0, 0, layer);

  }
  

  removeLayer(i) {
 
    console.log('removed layer' + i)
    this.arr[i].show = false
    this.arr.splice(i,1)
    this.layer.splice(i,1)
  }

  somethingChanged(event, i) {
    console.log(event)
    this.arr.splice(i, 1000, { text: event })
  }
  textChanged(event, i){
    console.log(event)
    this.arr.splice(i, 1000, { textSize: event })
  }
 


  front(i, id){

    this.up = +id + 1  
   
    this.arr[i].Z = this.up
  
  }
 

  back(i, id){
    this.down = +id - 1
      
    this.arr[i].Z =  this.down
 
  }

  open(i){
    this.arr[i].collapse =  false
    console.log('position'+ i)
  }
  openCrop(){
   this.cropOpen = false
  }
  closeCrop(){
    this.cropOpen = true
  }
  closeInstant(){
    this.instant = true
  }
  openInstant(){
    this.instant = false
  }
  canvasWidth(value){
    this.templateWidth = value;
    this.height = (<HTMLElement>this.screen.nativeElement).getBoundingClientRect().height;
    this.width = (<HTMLElement>this.screen.nativeElement).getBoundingClientRect().width;
  

    this.ngAfterViewInit()
   
  }
  canvasHeight(value){
  
    this.ngAfterViewInit()
    this.templateHeight = value;
  }
  close(i){
    this.arr[i].collapse =  true
    console.log('position'+ i)
  }
  centerText(i){
    this.arr[i].textAlignment =  'center'
  }
  rightText(i){
    this.arr[i].textAlignment =  'right'
  }
  leftText(i){
    this.arr[i].textAlignment =  'left'
  }
  ///////////////////////////--------------------Shape----------------///////////////////////////////////
  changeShapeColor(value,i){
console.log(value,i)
this.arr[i].shapeColor =  value
  }
  changeShapeWidth(value,i){
    console.log(value,i)
    this.arr[i].shapeWidth =  value
  }
  changeShapeHeight(value,i){
    console.log(value,i)
    this.arr[i].shapeHeight =  value
  }
  changeShapeRadius(value,i){
    console.log(value,i)
    this.arr[i].shapeRadius =  value
  }
  changeShapeRotation(value,i){
    console.log(value,i)
    this.arr[i].shapeRotate =  value
  }
  changeShapeTransparency(value,i){
    console.log(value,i)
    this.arr[i].shapeOpacity =  value
  }


  ///////////////////////////--------------------Text----------------///////////////////////////////////
  changeText(value, i){
    this.arr[i].textValue =  value
  }
  changeTextColor(value,i){
    this.arr[i].textColor =  value
  }
  changeTextAlignment(value, i){
    this.arr[i].textAlignment =  value
  }
  changeTextSize(value,i){
    this.arr[i].textSize =  value
  }
  changeTextBold(value, i){
    this.arr[i].textBold =  value
  }
  changeTextTransparency(value, i){
    this.arr[i].textOpacity =  value
  }
  changeTextRotation(value, i){
    this.arr[i].textRotation =  value
  }

///////////////////////////--------------------Image----------------///////////////////////////////////
  changeImageSize(value,i){
    this.arr[i].imageSize = value
      }
  changeImageRotation(value ,i){
    this.arr[i].imageRotate =  value
  }
  changeImageTransparency(value, i){
    this.arr[i].imageOpacity =  value
  }
  changeImageRadius(value, i){
    this.arr[i].imageRadius =  value
  }
///////////////////////////--------------------sizes----------------///////////////////////////////////

facebook(){
  this.templateWidth = 80
  this.templateHeight = 80
}
twitter(){

  this.templateWidth = 80
  this.templateHeight = 45
}
instagram(){
  this.templateWidth = 80
  this.templateHeight = 80
}

///////////////////////////---------------------font------------------/////////////////////////////////////

changeFont(value, i){
  this.arr[i].font =  value
}

showRapid(id,coBrandCard) {

  this.num++

  const layer = {
    type: 'Signature',
    textValue: '',
    id: this.num,
    Z: this.num,
    imageSize: 50,
    image: coBrandCard,
    imageRotate: 0,
    imageRadius: 0,
    imageOpacity: 100,
    shapeWidth: 0,
    shapeHeight: 0,
    shapeColor: '#000',
    shapeRadius: 0,
    shapeOpacity: 100,
    shapeRotate: 0,
    textSize: 3,
    textColor:'#000',
    textOpacity: 100,
    textRotate: 0,
    textBold: 300,
    textAlignment: 'center',
    show: true,
    collapse: false,
    font:'Work_Sans'
   

  }
  this.layer.splice(0, 0,layer);
  this.arr.splice(0, 0, layer);

}
closeLibrary(){
  this.library = true
}
openLibrary(){
  this.library = false
}
showLibrary(id,coBrandCard) {

  this.num++

  const layer = {
    type: 'Library',
    textValue: '',
    id: this.num,
    Z: this.num,
    imageSize: 50,
    image: coBrandCard,
    imageRotate: 0,
    imageRadius: 0,
    imageOpacity: 100,
    shapeWidth: 10,
    shapeHeight: 10,
    shapeColor: '#000',
    shapeRadius: 0,
    shapeOpacity: 100,
    shapeRotate: 0,
    textSize: 3,
    textColor:'#000',
    textOpacity: 100,
    textRotate: 0,
    textBold: 300,
    textAlignment: 'center',
    show: true,
    collapse: false,
    font:'Work_Sans'
   

  }
  this.layer.splice(0, 0,layer);
  this.arr.splice(0, 0, layer);

}
lockImage(){
  this.locked = true;
  // this.afs.collection('images').doc(this.id).update({
  //   locked:true
  // })
}
unlockImage(){
  this.locked = false;
  // this.afs.collection('images').doc(this.id).update({
  //   locked:false
  // })
}
lightText(){
  this.textColor = 'light'
  this.fontColor = '#fff'
  this.light = true;
  this.dark = false;
  console.log(this.light)
  // this.afs.collection('images').doc(this.id).update({
  //   textColor:'light'

  //  })
 }
 darkText(){
  this.textColor = 'dark'
  this.fontColor = '#000'
  this.dark = true;
  this.light = false;
  console.log(this.dark)
  // this.afs.collection('images').doc(this.id).update({
  //   textColor:'dark'

  //  })
 }
}

