
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { merge } from 'rxjs-compat/operator/merge';
import firebase from 'firebase/compat/app';
import Swal from 'sweetalert2';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any;
  profileRef: any;
  profile: any;
  profileState: any;
  isLoggedIn: boolean;
  newEmail: import("@angular/fire/compat/firestore").AngularFirestoreDocument<unknown>;
  updateNewEmail: any;
  successNewEmail: boolean;
  stripeId: any;
  customerConfirmation: any;
  paymentMethod: any;
  upcomingInvoice: any;

  constructor(private angularFireAuth: AngularFireAuth, public afs: AngularFirestore, private router: Router,  // Inject Firestore service
    public afAuth: AngularFireAuth, public functions: AngularFireFunctions,  ) {
    // this.userData = angularFireAuth.authState;
    
   this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.profileRef = this.afs.doc('users/'+ user.uid).valueChanges().subscribe(async data => {
          this.profile = data;
          if(this.profile.status == 'active'){
          this.stripeId = this.profile.stripeCustomerId
          const fun = this.functions.httpsCallable('getCustomer');
          this.customerConfirmation = await fun({ customer: this.profile.stripeCustomerId}).toPromise();
     
          const fun2 = this.functions.httpsCallable('paymentMethod');
          this.paymentMethod = await fun2({ customer: this.profile.stripeCustomerId}).toPromise();
      
          const fun3 = this.functions.httpsCallable('upcomingInvoice');
          this.upcomingInvoice = await fun3({ customer: this.profile.stripeCustomerId }).toPromise();
        
          }
        });
    this.isLoggedIn = true
       
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(user));
        JSON.parse(localStorage.getItem('user'));

      } else {
        this.isLoggedIn = false
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
     
     
    })
//     this.afAuth.onAuthStateChanged(user => {
//       if (user) {
//         // logged in or user exists
//         this.profileState = user;
//       console.log(this.profileState)
//       }
//       else {
//         // not logged in
//         this.profileState = user;
//       console.log(this.profileState)
//       }
// })
  }

  /* Sign up */
  SignUp(email: string, password: string, display:string) {
    this.angularFireAuth
      .createUserWithEmailAndPassword(email, password)
      .then(res => {
        this.afAuth.authState.subscribe(async user => {
          if(user) {
        this.afs.collection('users/').doc(user.uid).set({
          email: email,
          uid: user.uid,
          phone:'',
          firstName: '',
          lastName: '',
          rapidCard: true,
          showCard: true,
          following:[],
          followers:[],
          screenName: user.uid,
          displayName:'@'+display
        })
        const increment = firebase.firestore.FieldValue.increment(1);
          this.afs.doc('stats/users').update({
            users: increment,
            
          })
      }})
        console.log('Successfully signed up!', res);
        this.router.navigate(['/user-profile']); 
      })
      .catch(error => {
        console.log('Something is wrong:', error.message);
      });    
  }

  /* Sign in */
  SignIn(email: string, password: string) {
    this.angularFireAuth
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        console.log('Successfully signed in!');
      
        this.router.navigate(['/user-profile']); 
      })
      .catch(err => {
        console.log('Something is wrong:',err.message);
      });
  }

  /* Sign out */
  SignOut() {
    this.angularFireAuth
      .signOut().then();{
        this.router.navigate(['/login']); 
      };
  }  
  update(uid, firstName, lastName, phone, email){
    this.afs.collection('users').doc(uid).set({
      firstName: firstName,
      lastName: lastName,
      phone:phone,
      email:email

    }, { merge: true})
  }
  updatePassword(){
    var user = firebase.auth().currentUser;
    var auth = firebase.auth();
    var emailAddress = user.email;
    console.log(emailAddress);
 
    
    auth.sendPasswordResetEmail(emailAddress).then(function() {
      // Email sent.
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      
      Toast.fire({
        icon: 'success',
        title: 'Check your inbox, password re-set email sent!'
      })
    
    }).catch(function(error) {
      // An error happened.
      alert(error);
    });
  }

//   updateEmail(email, id){

//     var user = firebase.auth().currentUser;
//     console.log(user, email);

// user.updateEmail(email).then(function() {
//   // Update successful.
//   // this.newUserEmail = this.afAuth.auth.currentUser;
//   const Toast = Swal.mixin({
//     toast: true,
//     position: 'top',
//     showConfirmButton: false,
//     timer: 3000,
    
//     didOpen: (toast) => {
//       toast.addEventListener('mouseenter', Swal.stopTimer)
//       toast.addEventListener('mouseleave', Swal.resumeTimer)
//     }
//   })
  
//   Toast.fire({
//     icon: 'success',
//     title: 'Email Address Updated!'
//   })
//   this.successNewEmail = true
//   })
// .catch(function(error) {
//   const Toast = Swal.mixin({
//     toast: true,
//     position: 'top',
//     showConfirmButton: false,
  
//   })
  
//   Toast.fire({
//     icon: 'error',
//     title: error.message
//   })
//   console.log(error);

// })  
// .then(() => {
//   if(this.successNewEmail == true){
//     console.log('Now change it')
//     this.afs.collection('users').doc(user.uid).set({
//       email: email
    
//     }, { merge: true})
//   }
//   console.log('Jumped the gun');

 

// })

// }
updateEmail(email){

  var user = firebase.auth().currentUser;

user.updateEmail(email).then(() => {
// Update successful.
// this.newUserEmail = this.afAuth.auth.currentUser;
console.log(user.uid, email);
this.newEmail = this.afs.doc('users/'+ user.uid);
this.updateNewEmail = this.newEmail.update({
  email: email
}).then(() => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  
  Toast.fire({
    icon: 'success',
    title: 'Email Address Updated!'
  })
})
return 
})
.catch(function(error) {
console.log(error);
alert(error)
}).then();{
  

}
}
forgot(email){

  var auth = firebase.auth();

  console.log(email);

  
  auth.sendPasswordResetEmail(email).then(function() {
    // Email sent.
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 3000,
      
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    
    Toast.fire({
      icon: 'success',
      title: 'Check your inbox, password re-set email sent!'
    })
  
  }).catch(function(error) {
    // An error happened.
    alert(error);
  });
}

}